/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePersonInit = `subscription OnCreatePersonInit($owner: String) {
  onCreatePersonInit(owner: $owner) {
    id
    eMail
    personId
    businessPartnerId
    personBusinessPartnerMapPersonId
    productConditionMapId
    productId
    depotId
    savingsPlansId
    clearingAccountId
    liquidAccountId
    executionArn
    owner
    members
  }
}
`;
export const onUpdatePersonInit = `subscription OnUpdatePersonInit($owner: String) {
  onUpdatePersonInit(owner: $owner) {
    id
    eMail
    personId
    businessPartnerId
    personBusinessPartnerMapPersonId
    productConditionMapId
    productId
    depotId
    savingsPlansId
    clearingAccountId
    liquidAccountId
    executionArn
    owner
    members
  }
}
`;
export const onDeletePersonInit = `subscription OnDeletePersonInit($owner: String) {
  onDeletePersonInit(owner: $owner) {
    id
    eMail
    personId
    businessPartnerId
    personBusinessPartnerMapPersonId
    productConditionMapId
    productId
    depotId
    savingsPlansId
    clearingAccountId
    liquidAccountId
    executionArn
    owner
    members
  }
}
`;
export const onCreatePerson = `subscription OnCreatePerson($owner: String) {
  onCreatePerson(owner: $owner) {
    id
    eMail
    externalId
    avaloqId
    postIdent
    communicationEMail
    communicationEMailVerified
    dataComplete
    needToMigrateDoc
    identityId
    gender
    salutation
    title
    titleAdd
    firstName
    middleName
    lastName
    phoneNumber
    mobilePhoneNumber
    passportNumber
    passportIssuer
    passportIssuerDate
    passportValidToDate
    passportPlaceOfIssue
    passportCountryOfIssuer
    birthCertificateNumber
    birthCertificateIssuer
    birthCertificateIssuerDate
    birthCertificateCountryOfIssuer
    birthCertificateInitialEMail
    nationality
    street
    streetNumber
    zip
    city
    state
    country
    addressType
    maritalStatus
    taxNumber
    birthday
    birthplace
    nativeCountry
    occupation
    occupationIndustry
    taxOnlyInGermany
    additionalTax
    mmw
    campaign
    postIdentCaseId
    identificationMethod
    identificationStatus
    identificationType
    utmSource
    utmMedium
    utmCampaign
    utmData
    ndl {
      id
      name
      city
      zip
      street
      streetNr
    }
    businessPartners {
      items {
        id
        role
        waitForCode
        waitForBirthCertificate
        bpId
        createdAt
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    postbox {
      items {
        id
        title
        date
        readTime
        status
        documentKey
        searchField
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    orders {
      items {
        id
        productId
        businessPartnerId
        personId
        amount
        sfType
        sfARN
        sfStartDate
        status
        createdAt
        EndOfStateMachine
        stateMachineToken
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        subOrder {
          items {
            id
            chain
            amount
            status
            type
            createdAt
            transaction {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            order {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    consultant {
      id
      branch
      branchStreet
      branchZip
      branchCity
      branchState
      counter
      firstName
      lastName
      email
      phoneNumber
      mobilePhoneNumber
      status
      s3BucketPicLink
      createdAt
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
    }
    owner
    members
  }
}
`;
export const onUpdatePerson = `subscription OnUpdatePerson($owner: String) {
  onUpdatePerson(owner: $owner) {
    id
    eMail
    externalId
    avaloqId
    postIdent
    communicationEMail
    communicationEMailVerified
    dataComplete
    needToMigrateDoc
    identityId
    gender
    salutation
    title
    titleAdd
    firstName
    middleName
    lastName
    phoneNumber
    mobilePhoneNumber
    passportNumber
    passportIssuer
    passportIssuerDate
    passportValidToDate
    passportPlaceOfIssue
    passportCountryOfIssuer
    birthCertificateNumber
    birthCertificateIssuer
    birthCertificateIssuerDate
    birthCertificateCountryOfIssuer
    birthCertificateInitialEMail
    nationality
    street
    streetNumber
    zip
    city
    state
    country
    addressType
    maritalStatus
    taxNumber
    birthday
    birthplace
    nativeCountry
    occupation
    occupationIndustry
    taxOnlyInGermany
    additionalTax
    mmw
    campaign
    postIdentCaseId
    identificationMethod
    identificationStatus
    identificationType
    utmSource
    utmMedium
    utmCampaign
    utmData
    ndl {
      id
      name
      city
      zip
      street
      streetNr
    }
    businessPartners {
      items {
        id
        role
        waitForCode
        waitForBirthCertificate
        bpId
        createdAt
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    postbox {
      items {
        id
        title
        date
        readTime
        status
        documentKey
        searchField
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    orders {
      items {
        id
        productId
        businessPartnerId
        personId
        amount
        sfType
        sfARN
        sfStartDate
        status
        createdAt
        EndOfStateMachine
        stateMachineToken
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        subOrder {
          items {
            id
            chain
            amount
            status
            type
            createdAt
            transaction {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            order {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    consultant {
      id
      branch
      branchStreet
      branchZip
      branchCity
      branchState
      counter
      firstName
      lastName
      email
      phoneNumber
      mobilePhoneNumber
      status
      s3BucketPicLink
      createdAt
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
    }
    owner
    members
  }
}
`;
export const onDeletePerson = `subscription OnDeletePerson($owner: String) {
  onDeletePerson(owner: $owner) {
    id
    eMail
    externalId
    avaloqId
    postIdent
    communicationEMail
    communicationEMailVerified
    dataComplete
    needToMigrateDoc
    identityId
    gender
    salutation
    title
    titleAdd
    firstName
    middleName
    lastName
    phoneNumber
    mobilePhoneNumber
    passportNumber
    passportIssuer
    passportIssuerDate
    passportValidToDate
    passportPlaceOfIssue
    passportCountryOfIssuer
    birthCertificateNumber
    birthCertificateIssuer
    birthCertificateIssuerDate
    birthCertificateCountryOfIssuer
    birthCertificateInitialEMail
    nationality
    street
    streetNumber
    zip
    city
    state
    country
    addressType
    maritalStatus
    taxNumber
    birthday
    birthplace
    nativeCountry
    occupation
    occupationIndustry
    taxOnlyInGermany
    additionalTax
    mmw
    campaign
    postIdentCaseId
    identificationMethod
    identificationStatus
    identificationType
    utmSource
    utmMedium
    utmCampaign
    utmData
    ndl {
      id
      name
      city
      zip
      street
      streetNr
    }
    businessPartners {
      items {
        id
        role
        waitForCode
        waitForBirthCertificate
        bpId
        createdAt
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    postbox {
      items {
        id
        title
        date
        readTime
        status
        documentKey
        searchField
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    orders {
      items {
        id
        productId
        businessPartnerId
        personId
        amount
        sfType
        sfARN
        sfStartDate
        status
        createdAt
        EndOfStateMachine
        stateMachineToken
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        subOrder {
          items {
            id
            chain
            amount
            status
            type
            createdAt
            transaction {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            order {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    consultant {
      id
      branch
      branchStreet
      branchZip
      branchCity
      branchState
      counter
      firstName
      lastName
      email
      phoneNumber
      mobilePhoneNumber
      status
      s3BucketPicLink
      createdAt
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
    }
    owner
    members
  }
}
`;
export const onCreatePersonBusinessPartnerMap = `subscription OnCreatePersonBusinessPartnerMap($owner: String) {
  onCreatePersonBusinessPartnerMap(owner: $owner) {
    id
    role
    waitForCode
    waitForBirthCertificate
    bpId
    createdAt
    businessPartner {
      id
      number
      accountType
      name
      statusContract
      avaloqSync
      advisor
      branch
      balanceActual
      balanceDate
      balanceStart
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      joinedMember
      childMember
      otherMembers
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      stateMachineToken
      createdAt
      openedAt
      closedAt
      wphgExpireDate
      wphg
      businessPartnerCode {
        items {
          id
          attorney
          businessPartnerId
          accountCode
          accountCodeStatus
          acceptedByOwner
          owner
          members
        }
        nextToken
      }
      persons {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      products {
        items {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      bankAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    person {
      id
      eMail
      externalId
      avaloqId
      postIdent
      communicationEMail
      communicationEMailVerified
      dataComplete
      needToMigrateDoc
      identityId
      gender
      salutation
      title
      titleAdd
      firstName
      middleName
      lastName
      phoneNumber
      mobilePhoneNumber
      passportNumber
      passportIssuer
      passportIssuerDate
      passportValidToDate
      passportPlaceOfIssue
      passportCountryOfIssuer
      birthCertificateNumber
      birthCertificateIssuer
      birthCertificateIssuerDate
      birthCertificateCountryOfIssuer
      birthCertificateInitialEMail
      nationality
      street
      streetNumber
      zip
      city
      state
      country
      addressType
      maritalStatus
      taxNumber
      birthday
      birthplace
      nativeCountry
      occupation
      occupationIndustry
      taxOnlyInGermany
      additionalTax
      mmw
      campaign
      postIdentCaseId
      identificationMethod
      identificationStatus
      identificationType
      utmSource
      utmMedium
      utmCampaign
      utmData
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
      businessPartners {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      postbox {
        items {
          id
          title
          date
          readTime
          status
          documentKey
          searchField
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onUpdatePersonBusinessPartnerMap = `subscription OnUpdatePersonBusinessPartnerMap($owner: String) {
  onUpdatePersonBusinessPartnerMap(owner: $owner) {
    id
    role
    waitForCode
    waitForBirthCertificate
    bpId
    createdAt
    businessPartner {
      id
      number
      accountType
      name
      statusContract
      avaloqSync
      advisor
      branch
      balanceActual
      balanceDate
      balanceStart
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      joinedMember
      childMember
      otherMembers
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      stateMachineToken
      createdAt
      openedAt
      closedAt
      wphgExpireDate
      wphg
      businessPartnerCode {
        items {
          id
          attorney
          businessPartnerId
          accountCode
          accountCodeStatus
          acceptedByOwner
          owner
          members
        }
        nextToken
      }
      persons {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      products {
        items {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      bankAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    person {
      id
      eMail
      externalId
      avaloqId
      postIdent
      communicationEMail
      communicationEMailVerified
      dataComplete
      needToMigrateDoc
      identityId
      gender
      salutation
      title
      titleAdd
      firstName
      middleName
      lastName
      phoneNumber
      mobilePhoneNumber
      passportNumber
      passportIssuer
      passportIssuerDate
      passportValidToDate
      passportPlaceOfIssue
      passportCountryOfIssuer
      birthCertificateNumber
      birthCertificateIssuer
      birthCertificateIssuerDate
      birthCertificateCountryOfIssuer
      birthCertificateInitialEMail
      nationality
      street
      streetNumber
      zip
      city
      state
      country
      addressType
      maritalStatus
      taxNumber
      birthday
      birthplace
      nativeCountry
      occupation
      occupationIndustry
      taxOnlyInGermany
      additionalTax
      mmw
      campaign
      postIdentCaseId
      identificationMethod
      identificationStatus
      identificationType
      utmSource
      utmMedium
      utmCampaign
      utmData
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
      businessPartners {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      postbox {
        items {
          id
          title
          date
          readTime
          status
          documentKey
          searchField
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onDeletePersonBusinessPartnerMap = `subscription OnDeletePersonBusinessPartnerMap($owner: String) {
  onDeletePersonBusinessPartnerMap(owner: $owner) {
    id
    role
    waitForCode
    waitForBirthCertificate
    bpId
    createdAt
    businessPartner {
      id
      number
      accountType
      name
      statusContract
      avaloqSync
      advisor
      branch
      balanceActual
      balanceDate
      balanceStart
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      joinedMember
      childMember
      otherMembers
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      stateMachineToken
      createdAt
      openedAt
      closedAt
      wphgExpireDate
      wphg
      businessPartnerCode {
        items {
          id
          attorney
          businessPartnerId
          accountCode
          accountCodeStatus
          acceptedByOwner
          owner
          members
        }
        nextToken
      }
      persons {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      products {
        items {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      bankAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    person {
      id
      eMail
      externalId
      avaloqId
      postIdent
      communicationEMail
      communicationEMailVerified
      dataComplete
      needToMigrateDoc
      identityId
      gender
      salutation
      title
      titleAdd
      firstName
      middleName
      lastName
      phoneNumber
      mobilePhoneNumber
      passportNumber
      passportIssuer
      passportIssuerDate
      passportValidToDate
      passportPlaceOfIssue
      passportCountryOfIssuer
      birthCertificateNumber
      birthCertificateIssuer
      birthCertificateIssuerDate
      birthCertificateCountryOfIssuer
      birthCertificateInitialEMail
      nationality
      street
      streetNumber
      zip
      city
      state
      country
      addressType
      maritalStatus
      taxNumber
      birthday
      birthplace
      nativeCountry
      occupation
      occupationIndustry
      taxOnlyInGermany
      additionalTax
      mmw
      campaign
      postIdentCaseId
      identificationMethod
      identificationStatus
      identificationType
      utmSource
      utmMedium
      utmCampaign
      utmData
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
      businessPartners {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      postbox {
        items {
          id
          title
          date
          readTime
          status
          documentKey
          searchField
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onCreateConsultant = `subscription OnCreateConsultant {
  onCreateConsultant {
    id
    branch
    branchStreet
    branchZip
    branchCity
    branchState
    counter
    firstName
    lastName
    email
    phoneNumber
    mobilePhoneNumber
    status
    s3BucketPicLink
    createdAt
    ndl {
      id
      name
      city
      zip
      street
      streetNr
    }
  }
}
`;
export const onUpdateConsultant = `subscription OnUpdateConsultant {
  onUpdateConsultant {
    id
    branch
    branchStreet
    branchZip
    branchCity
    branchState
    counter
    firstName
    lastName
    email
    phoneNumber
    mobilePhoneNumber
    status
    s3BucketPicLink
    createdAt
    ndl {
      id
      name
      city
      zip
      street
      streetNr
    }
  }
}
`;
export const onDeleteConsultant = `subscription OnDeleteConsultant {
  onDeleteConsultant {
    id
    branch
    branchStreet
    branchZip
    branchCity
    branchState
    counter
    firstName
    lastName
    email
    phoneNumber
    mobilePhoneNumber
    status
    s3BucketPicLink
    createdAt
    ndl {
      id
      name
      city
      zip
      street
      streetNr
    }
  }
}
`;
export const onCreateNdl = `subscription OnCreateNdl {
  onCreateNdl {
    id
    name
    city
    zip
    street
    streetNr
  }
}
`;
export const onUpdateNdl = `subscription OnUpdateNdl {
  onUpdateNdl {
    id
    name
    city
    zip
    street
    streetNr
  }
}
`;
export const onDeleteNdl = `subscription OnDeleteNdl {
  onDeleteNdl {
    id
    name
    city
    zip
    street
    streetNr
  }
}
`;
export const onCreateZipCodeNdl = `subscription OnCreateZipCodeNdl {
  onCreateZipCodeNdl {
    id
    zipcode
    state
    ndl {
      id
      name
      city
      zip
      street
      streetNr
    }
  }
}
`;
export const onUpdateZipCodeNdl = `subscription OnUpdateZipCodeNdl {
  onUpdateZipCodeNdl {
    id
    zipcode
    state
    ndl {
      id
      name
      city
      zip
      street
      streetNr
    }
  }
}
`;
export const onDeleteZipCodeNdl = `subscription OnDeleteZipCodeNdl {
  onDeleteZipCodeNdl {
    id
    zipcode
    state
    ndl {
      id
      name
      city
      zip
      street
      streetNr
    }
  }
}
`;
export const onCreateBusinessPartner = `subscription OnCreateBusinessPartner($owner: String, $members: String) {
  onCreateBusinessPartner(owner: $owner, members: $members) {
    id
    number
    accountType
    name
    statusContract
    avaloqSync
    advisor
    branch
    balanceActual
    balanceDate
    balanceStart
    totalChangeBegin
    totalChangeYearStart
    twrPerformanceStart
    twrPerformanceYearStart
    mwrPerformanceStart
    mwrPerformanceYearStart
    joinedMember
    childMember
    otherMembers
    portfolioHistory
    portfolioHistoryYear
    performanceStartDate
    stateMachineToken
    createdAt
    openedAt
    closedAt
    wphgExpireDate
    wphg
    businessPartnerCode {
      items {
        id
        attorney
        businessPartnerId
        accountCode
        accountCodeStatus
        acceptedByOwner
        owner
        members
      }
      nextToken
    }
    persons {
      items {
        id
        role
        waitForCode
        waitForBirthCertificate
        bpId
        createdAt
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    products {
      items {
        id
        number
        name
        category
        strategy
        recommendedStrategy
        recommendedName
        balanceActual
        balanceStart
        balanceDate
        totalChangeBegin
        totalChangeYearStart
        twrPerformanceStart
        twrPerformanceYearStart
        mwrPerformanceStart
        mwrPerformanceYearStart
        statusProduct
        portfolioHistory
        portfolioHistoryYear
        performanceStartDate
        retirementYear
        stateMachineToken
        wphgInitialAmount
        initialAmountReceived
        wphgExpireDate
        wphg
        balanceClosed
        balanceClosedDate
        createdAt
        depotBalance
        depotItems
        cashoutPlan {
          id
          rate
          counter
          interval
          startDate
          endDate
          nextDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        liquidAccounts {
          items {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        depots {
          items {
            id
            number
            balance
            twr
            mwr
            profit
            depotItems {
              items {
                id
                name
                number
                quantity
                value
                valueCurrency
                einstandskurs
                aktuellerKurs
                notRealGUV
                notRealGUVCurrency
                notRealGUVPercentage
                referenceDate
                avaloqAssetId
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        conditions {
          items {
            id
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            condition {
              id
              advisor
              category
              strategy
              accountType
              minInvest
              minIncrease
              minDecrease
              minRate
              allowance
              cashoutplan
              savingsplan
              consulting
              feeQuirion
              feeAdvisor
              validFrom
              validTo
              homepageUrl
              conditionLink
              advisorConfirmText
              contact
              phoneNumber
              description
              descriptionTitle
              title
              name
              newBp
              newIps
              costProfile
              products {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
            }
            owner
            members
          }
          nextToken
        }
        actions {
          items {
            id
            action {
              id
              trigger
              dateFrom
              dateTo
              advisor
              category
              accountType
              existingCustomer
              newCustomer
              tasks
              name
              actionTCLink
              accept
              products {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    orders {
      items {
        id
        productId
        businessPartnerId
        personId
        amount
        sfType
        sfARN
        sfStartDate
        status
        createdAt
        EndOfStateMachine
        stateMachineToken
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        subOrder {
          items {
            id
            chain
            amount
            status
            type
            createdAt
            transaction {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            order {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    bankAccounts {
      items {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    consultant {
      id
      branch
      branchStreet
      branchZip
      branchCity
      branchState
      counter
      firstName
      lastName
      email
      phoneNumber
      mobilePhoneNumber
      status
      s3BucketPicLink
      createdAt
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
    }
    owner
    members
  }
}
`;
export const onUpdateBusinessPartner = `subscription OnUpdateBusinessPartner($owner: String, $members: String) {
  onUpdateBusinessPartner(owner: $owner, members: $members) {
    id
    number
    accountType
    name
    statusContract
    avaloqSync
    advisor
    branch
    balanceActual
    balanceDate
    balanceStart
    totalChangeBegin
    totalChangeYearStart
    twrPerformanceStart
    twrPerformanceYearStart
    mwrPerformanceStart
    mwrPerformanceYearStart
    joinedMember
    childMember
    otherMembers
    portfolioHistory
    portfolioHistoryYear
    performanceStartDate
    stateMachineToken
    createdAt
    openedAt
    closedAt
    wphgExpireDate
    wphg
    businessPartnerCode {
      items {
        id
        attorney
        businessPartnerId
        accountCode
        accountCodeStatus
        acceptedByOwner
        owner
        members
      }
      nextToken
    }
    persons {
      items {
        id
        role
        waitForCode
        waitForBirthCertificate
        bpId
        createdAt
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    products {
      items {
        id
        number
        name
        category
        strategy
        recommendedStrategy
        recommendedName
        balanceActual
        balanceStart
        balanceDate
        totalChangeBegin
        totalChangeYearStart
        twrPerformanceStart
        twrPerformanceYearStart
        mwrPerformanceStart
        mwrPerformanceYearStart
        statusProduct
        portfolioHistory
        portfolioHistoryYear
        performanceStartDate
        retirementYear
        stateMachineToken
        wphgInitialAmount
        initialAmountReceived
        wphgExpireDate
        wphg
        balanceClosed
        balanceClosedDate
        createdAt
        depotBalance
        depotItems
        cashoutPlan {
          id
          rate
          counter
          interval
          startDate
          endDate
          nextDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        liquidAccounts {
          items {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        depots {
          items {
            id
            number
            balance
            twr
            mwr
            profit
            depotItems {
              items {
                id
                name
                number
                quantity
                value
                valueCurrency
                einstandskurs
                aktuellerKurs
                notRealGUV
                notRealGUVCurrency
                notRealGUVPercentage
                referenceDate
                avaloqAssetId
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        conditions {
          items {
            id
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            condition {
              id
              advisor
              category
              strategy
              accountType
              minInvest
              minIncrease
              minDecrease
              minRate
              allowance
              cashoutplan
              savingsplan
              consulting
              feeQuirion
              feeAdvisor
              validFrom
              validTo
              homepageUrl
              conditionLink
              advisorConfirmText
              contact
              phoneNumber
              description
              descriptionTitle
              title
              name
              newBp
              newIps
              costProfile
              products {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
            }
            owner
            members
          }
          nextToken
        }
        actions {
          items {
            id
            action {
              id
              trigger
              dateFrom
              dateTo
              advisor
              category
              accountType
              existingCustomer
              newCustomer
              tasks
              name
              actionTCLink
              accept
              products {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    orders {
      items {
        id
        productId
        businessPartnerId
        personId
        amount
        sfType
        sfARN
        sfStartDate
        status
        createdAt
        EndOfStateMachine
        stateMachineToken
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        subOrder {
          items {
            id
            chain
            amount
            status
            type
            createdAt
            transaction {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            order {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    bankAccounts {
      items {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    consultant {
      id
      branch
      branchStreet
      branchZip
      branchCity
      branchState
      counter
      firstName
      lastName
      email
      phoneNumber
      mobilePhoneNumber
      status
      s3BucketPicLink
      createdAt
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
    }
    owner
    members
  }
}
`;
export const onDeleteBusinessPartner = `subscription OnDeleteBusinessPartner($owner: String, $members: String) {
  onDeleteBusinessPartner(owner: $owner, members: $members) {
    id
    number
    accountType
    name
    statusContract
    avaloqSync
    advisor
    branch
    balanceActual
    balanceDate
    balanceStart
    totalChangeBegin
    totalChangeYearStart
    twrPerformanceStart
    twrPerformanceYearStart
    mwrPerformanceStart
    mwrPerformanceYearStart
    joinedMember
    childMember
    otherMembers
    portfolioHistory
    portfolioHistoryYear
    performanceStartDate
    stateMachineToken
    createdAt
    openedAt
    closedAt
    wphgExpireDate
    wphg
    businessPartnerCode {
      items {
        id
        attorney
        businessPartnerId
        accountCode
        accountCodeStatus
        acceptedByOwner
        owner
        members
      }
      nextToken
    }
    persons {
      items {
        id
        role
        waitForCode
        waitForBirthCertificate
        bpId
        createdAt
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    products {
      items {
        id
        number
        name
        category
        strategy
        recommendedStrategy
        recommendedName
        balanceActual
        balanceStart
        balanceDate
        totalChangeBegin
        totalChangeYearStart
        twrPerformanceStart
        twrPerformanceYearStart
        mwrPerformanceStart
        mwrPerformanceYearStart
        statusProduct
        portfolioHistory
        portfolioHistoryYear
        performanceStartDate
        retirementYear
        stateMachineToken
        wphgInitialAmount
        initialAmountReceived
        wphgExpireDate
        wphg
        balanceClosed
        balanceClosedDate
        createdAt
        depotBalance
        depotItems
        cashoutPlan {
          id
          rate
          counter
          interval
          startDate
          endDate
          nextDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        liquidAccounts {
          items {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        depots {
          items {
            id
            number
            balance
            twr
            mwr
            profit
            depotItems {
              items {
                id
                name
                number
                quantity
                value
                valueCurrency
                einstandskurs
                aktuellerKurs
                notRealGUV
                notRealGUVCurrency
                notRealGUVPercentage
                referenceDate
                avaloqAssetId
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        conditions {
          items {
            id
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            condition {
              id
              advisor
              category
              strategy
              accountType
              minInvest
              minIncrease
              minDecrease
              minRate
              allowance
              cashoutplan
              savingsplan
              consulting
              feeQuirion
              feeAdvisor
              validFrom
              validTo
              homepageUrl
              conditionLink
              advisorConfirmText
              contact
              phoneNumber
              description
              descriptionTitle
              title
              name
              newBp
              newIps
              costProfile
              products {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
            }
            owner
            members
          }
          nextToken
        }
        actions {
          items {
            id
            action {
              id
              trigger
              dateFrom
              dateTo
              advisor
              category
              accountType
              existingCustomer
              newCustomer
              tasks
              name
              actionTCLink
              accept
              products {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    orders {
      items {
        id
        productId
        businessPartnerId
        personId
        amount
        sfType
        sfARN
        sfStartDate
        status
        createdAt
        EndOfStateMachine
        stateMachineToken
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        subOrder {
          items {
            id
            chain
            amount
            status
            type
            createdAt
            transaction {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            order {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    bankAccounts {
      items {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    consultant {
      id
      branch
      branchStreet
      branchZip
      branchCity
      branchState
      counter
      firstName
      lastName
      email
      phoneNumber
      mobilePhoneNumber
      status
      s3BucketPicLink
      createdAt
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
    }
    owner
    members
  }
}
`;
export const onCreateBusinessPartnerCode = `subscription OnCreateBusinessPartnerCode($owner: String, $members: String) {
  onCreateBusinessPartnerCode(owner: $owner, members: $members) {
    id
    attorney
    businessPartnerId
    accountCode
    accountCodeStatus
    acceptedByOwner
    owner
    members
  }
}
`;
export const onUpdateBusinessPartnerCode = `subscription OnUpdateBusinessPartnerCode($owner: String, $members: String) {
  onUpdateBusinessPartnerCode(owner: $owner, members: $members) {
    id
    attorney
    businessPartnerId
    accountCode
    accountCodeStatus
    acceptedByOwner
    owner
    members
  }
}
`;
export const onDeleteBusinessPartnerCode = `subscription OnDeleteBusinessPartnerCode($owner: String, $members: String) {
  onDeleteBusinessPartnerCode(owner: $owner, members: $members) {
    id
    attorney
    businessPartnerId
    accountCode
    accountCodeStatus
    acceptedByOwner
    owner
    members
  }
}
`;
export const onCreateMarketingAktion = `subscription OnCreateMarketingAktion($owner: String, $members: String) {
  onCreateMarketingAktion(owner: $owner, members: $members) {
    id
    personId
    businessPartnerId
    productId
    actionId
    case
    createdAt
    owner
    members
  }
}
`;
export const onUpdateMarketingAktion = `subscription OnUpdateMarketingAktion($owner: String, $members: String) {
  onUpdateMarketingAktion(owner: $owner, members: $members) {
    id
    personId
    businessPartnerId
    productId
    actionId
    case
    createdAt
    owner
    members
  }
}
`;
export const onDeleteMarketingAktion = `subscription OnDeleteMarketingAktion($owner: String, $members: String) {
  onDeleteMarketingAktion(owner: $owner, members: $members) {
    id
    personId
    businessPartnerId
    productId
    actionId
    case
    createdAt
    owner
    members
  }
}
`;
export const onCreateProduct = `subscription OnCreateProduct($owner: String, $members: String) {
  onCreateProduct(owner: $owner, members: $members) {
    id
    number
    name
    category
    strategy
    recommendedStrategy
    recommendedName
    balanceActual
    balanceStart
    balanceDate
    totalChangeBegin
    totalChangeYearStart
    twrPerformanceStart
    twrPerformanceYearStart
    mwrPerformanceStart
    mwrPerformanceYearStart
    statusProduct
    portfolioHistory
    portfolioHistoryYear
    performanceStartDate
    retirementYear
    stateMachineToken
    wphgInitialAmount
    initialAmountReceived
    wphgExpireDate
    wphg
    balanceClosed
    balanceClosedDate
    createdAt
    depotBalance
    depotItems
    cashoutPlan {
      id
      rate
      counter
      interval
      startDate
      endDate
      nextDate
      bankAccount {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      owner
      members
    }
    liquidAccounts {
      items {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    savingsPlans {
      items {
        id
        name
        rate
        startDate
        endDate
        nextDate
        executionDay
        counter
        interval
        mandat
        mandatSignatureDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    depots {
      items {
        id
        number
        balance
        twr
        mwr
        profit
        depotItems {
          items {
            id
            name
            number
            quantity
            value
            valueCurrency
            einstandskurs
            aktuellerKurs
            notRealGUV
            notRealGUVCurrency
            notRealGUVPercentage
            referenceDate
            avaloqAssetId
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    orders {
      items {
        id
        productId
        businessPartnerId
        personId
        amount
        sfType
        sfARN
        sfStartDate
        status
        createdAt
        EndOfStateMachine
        stateMachineToken
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        subOrder {
          items {
            id
            chain
            amount
            status
            type
            createdAt
            transaction {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            order {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    conditions {
      items {
        id
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        condition {
          id
          advisor
          category
          strategy
          accountType
          minInvest
          minIncrease
          minDecrease
          minRate
          allowance
          cashoutplan
          savingsplan
          consulting
          feeQuirion
          feeAdvisor
          validFrom
          validTo
          homepageUrl
          conditionLink
          advisorConfirmText
          contact
          phoneNumber
          description
          descriptionTitle
          title
          name
          newBp
          newIps
          costProfile
          products {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
        }
        owner
        members
      }
      nextToken
    }
    actions {
      items {
        id
        action {
          id
          trigger
          dateFrom
          dateTo
          advisor
          category
          accountType
          existingCustomer
          newCustomer
          tasks
          name
          actionTCLink
          accept
          products {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onUpdateProduct = `subscription OnUpdateProduct($owner: String, $members: String) {
  onUpdateProduct(owner: $owner, members: $members) {
    id
    number
    name
    category
    strategy
    recommendedStrategy
    recommendedName
    balanceActual
    balanceStart
    balanceDate
    totalChangeBegin
    totalChangeYearStart
    twrPerformanceStart
    twrPerformanceYearStart
    mwrPerformanceStart
    mwrPerformanceYearStart
    statusProduct
    portfolioHistory
    portfolioHistoryYear
    performanceStartDate
    retirementYear
    stateMachineToken
    wphgInitialAmount
    initialAmountReceived
    wphgExpireDate
    wphg
    balanceClosed
    balanceClosedDate
    createdAt
    depotBalance
    depotItems
    cashoutPlan {
      id
      rate
      counter
      interval
      startDate
      endDate
      nextDate
      bankAccount {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      owner
      members
    }
    liquidAccounts {
      items {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    savingsPlans {
      items {
        id
        name
        rate
        startDate
        endDate
        nextDate
        executionDay
        counter
        interval
        mandat
        mandatSignatureDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    depots {
      items {
        id
        number
        balance
        twr
        mwr
        profit
        depotItems {
          items {
            id
            name
            number
            quantity
            value
            valueCurrency
            einstandskurs
            aktuellerKurs
            notRealGUV
            notRealGUVCurrency
            notRealGUVPercentage
            referenceDate
            avaloqAssetId
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    orders {
      items {
        id
        productId
        businessPartnerId
        personId
        amount
        sfType
        sfARN
        sfStartDate
        status
        createdAt
        EndOfStateMachine
        stateMachineToken
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        subOrder {
          items {
            id
            chain
            amount
            status
            type
            createdAt
            transaction {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            order {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    conditions {
      items {
        id
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        condition {
          id
          advisor
          category
          strategy
          accountType
          minInvest
          minIncrease
          minDecrease
          minRate
          allowance
          cashoutplan
          savingsplan
          consulting
          feeQuirion
          feeAdvisor
          validFrom
          validTo
          homepageUrl
          conditionLink
          advisorConfirmText
          contact
          phoneNumber
          description
          descriptionTitle
          title
          name
          newBp
          newIps
          costProfile
          products {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
        }
        owner
        members
      }
      nextToken
    }
    actions {
      items {
        id
        action {
          id
          trigger
          dateFrom
          dateTo
          advisor
          category
          accountType
          existingCustomer
          newCustomer
          tasks
          name
          actionTCLink
          accept
          products {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onDeleteProduct = `subscription OnDeleteProduct($owner: String, $members: String) {
  onDeleteProduct(owner: $owner, members: $members) {
    id
    number
    name
    category
    strategy
    recommendedStrategy
    recommendedName
    balanceActual
    balanceStart
    balanceDate
    totalChangeBegin
    totalChangeYearStart
    twrPerformanceStart
    twrPerformanceYearStart
    mwrPerformanceStart
    mwrPerformanceYearStart
    statusProduct
    portfolioHistory
    portfolioHistoryYear
    performanceStartDate
    retirementYear
    stateMachineToken
    wphgInitialAmount
    initialAmountReceived
    wphgExpireDate
    wphg
    balanceClosed
    balanceClosedDate
    createdAt
    depotBalance
    depotItems
    cashoutPlan {
      id
      rate
      counter
      interval
      startDate
      endDate
      nextDate
      bankAccount {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      owner
      members
    }
    liquidAccounts {
      items {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    savingsPlans {
      items {
        id
        name
        rate
        startDate
        endDate
        nextDate
        executionDay
        counter
        interval
        mandat
        mandatSignatureDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    depots {
      items {
        id
        number
        balance
        twr
        mwr
        profit
        depotItems {
          items {
            id
            name
            number
            quantity
            value
            valueCurrency
            einstandskurs
            aktuellerKurs
            notRealGUV
            notRealGUVCurrency
            notRealGUVPercentage
            referenceDate
            avaloqAssetId
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    orders {
      items {
        id
        productId
        businessPartnerId
        personId
        amount
        sfType
        sfARN
        sfStartDate
        status
        createdAt
        EndOfStateMachine
        stateMachineToken
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        businessPartner {
          id
          number
          accountType
          name
          statusContract
          avaloqSync
          advisor
          branch
          balanceActual
          balanceDate
          balanceStart
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          joinedMember
          childMember
          otherMembers
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          stateMachineToken
          createdAt
          openedAt
          closedAt
          wphgExpireDate
          wphg
          businessPartnerCode {
            items {
              id
              attorney
              businessPartnerId
              accountCode
              accountCodeStatus
              acceptedByOwner
              owner
              members
            }
            nextToken
          }
          persons {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          products {
            items {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          bankAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        person {
          id
          eMail
          externalId
          avaloqId
          postIdent
          communicationEMail
          communicationEMailVerified
          dataComplete
          needToMigrateDoc
          identityId
          gender
          salutation
          title
          titleAdd
          firstName
          middleName
          lastName
          phoneNumber
          mobilePhoneNumber
          passportNumber
          passportIssuer
          passportIssuerDate
          passportValidToDate
          passportPlaceOfIssue
          passportCountryOfIssuer
          birthCertificateNumber
          birthCertificateIssuer
          birthCertificateIssuerDate
          birthCertificateCountryOfIssuer
          birthCertificateInitialEMail
          nationality
          street
          streetNumber
          zip
          city
          state
          country
          addressType
          maritalStatus
          taxNumber
          birthday
          birthplace
          nativeCountry
          occupation
          occupationIndustry
          taxOnlyInGermany
          additionalTax
          mmw
          campaign
          postIdentCaseId
          identificationMethod
          identificationStatus
          identificationType
          utmSource
          utmMedium
          utmCampaign
          utmData
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
          businessPartners {
            items {
              id
              role
              waitForCode
              waitForBirthCertificate
              bpId
              createdAt
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          postbox {
            items {
              id
              title
              date
              readTime
              status
              documentKey
              searchField
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          consultant {
            id
            branch
            branchStreet
            branchZip
            branchCity
            branchState
            counter
            firstName
            lastName
            email
            phoneNumber
            mobilePhoneNumber
            status
            s3BucketPicLink
            createdAt
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
          }
          owner
          members
        }
        subOrder {
          items {
            id
            chain
            amount
            status
            type
            createdAt
            transaction {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            order {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      nextToken
    }
    conditions {
      items {
        id
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        condition {
          id
          advisor
          category
          strategy
          accountType
          minInvest
          minIncrease
          minDecrease
          minRate
          allowance
          cashoutplan
          savingsplan
          consulting
          feeQuirion
          feeAdvisor
          validFrom
          validTo
          homepageUrl
          conditionLink
          advisorConfirmText
          contact
          phoneNumber
          description
          descriptionTitle
          title
          name
          newBp
          newIps
          costProfile
          products {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
        }
        owner
        members
      }
      nextToken
    }
    actions {
      items {
        id
        action {
          id
          trigger
          dateFrom
          dateTo
          advisor
          category
          accountType
          existingCustomer
          newCustomer
          tasks
          name
          actionTCLink
          accept
          products {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onCreateProductConditionMap = `subscription OnCreateProductConditionMap($owner: String, $members: String) {
  onCreateProductConditionMap(owner: $owner, members: $members) {
    id
    product {
      id
      number
      name
      category
      strategy
      recommendedStrategy
      recommendedName
      balanceActual
      balanceStart
      balanceDate
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      statusProduct
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      retirementYear
      stateMachineToken
      wphgInitialAmount
      initialAmountReceived
      wphgExpireDate
      wphg
      balanceClosed
      balanceClosedDate
      createdAt
      depotBalance
      depotItems
      cashoutPlan {
        id
        rate
        counter
        interval
        startDate
        endDate
        nextDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      liquidAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      depots {
        items {
          id
          number
          balance
          twr
          mwr
          profit
          depotItems {
            items {
              id
              name
              number
              quantity
              value
              valueCurrency
              einstandskurs
              aktuellerKurs
              notRealGUV
              notRealGUVCurrency
              notRealGUVPercentage
              referenceDate
              avaloqAssetId
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      conditions {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    condition {
      id
      advisor
      category
      strategy
      accountType
      minInvest
      minIncrease
      minDecrease
      minRate
      allowance
      cashoutplan
      savingsplan
      consulting
      feeQuirion
      feeAdvisor
      validFrom
      validTo
      homepageUrl
      conditionLink
      advisorConfirmText
      contact
      phoneNumber
      description
      descriptionTitle
      title
      name
      newBp
      newIps
      costProfile
      products {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
    }
    owner
    members
  }
}
`;
export const onUpdateProductConditionMap = `subscription OnUpdateProductConditionMap($owner: String, $members: String) {
  onUpdateProductConditionMap(owner: $owner, members: $members) {
    id
    product {
      id
      number
      name
      category
      strategy
      recommendedStrategy
      recommendedName
      balanceActual
      balanceStart
      balanceDate
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      statusProduct
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      retirementYear
      stateMachineToken
      wphgInitialAmount
      initialAmountReceived
      wphgExpireDate
      wphg
      balanceClosed
      balanceClosedDate
      createdAt
      depotBalance
      depotItems
      cashoutPlan {
        id
        rate
        counter
        interval
        startDate
        endDate
        nextDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      liquidAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      depots {
        items {
          id
          number
          balance
          twr
          mwr
          profit
          depotItems {
            items {
              id
              name
              number
              quantity
              value
              valueCurrency
              einstandskurs
              aktuellerKurs
              notRealGUV
              notRealGUVCurrency
              notRealGUVPercentage
              referenceDate
              avaloqAssetId
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      conditions {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    condition {
      id
      advisor
      category
      strategy
      accountType
      minInvest
      minIncrease
      minDecrease
      minRate
      allowance
      cashoutplan
      savingsplan
      consulting
      feeQuirion
      feeAdvisor
      validFrom
      validTo
      homepageUrl
      conditionLink
      advisorConfirmText
      contact
      phoneNumber
      description
      descriptionTitle
      title
      name
      newBp
      newIps
      costProfile
      products {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
    }
    owner
    members
  }
}
`;
export const onDeleteProductConditionMap = `subscription OnDeleteProductConditionMap($owner: String, $members: String) {
  onDeleteProductConditionMap(owner: $owner, members: $members) {
    id
    product {
      id
      number
      name
      category
      strategy
      recommendedStrategy
      recommendedName
      balanceActual
      balanceStart
      balanceDate
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      statusProduct
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      retirementYear
      stateMachineToken
      wphgInitialAmount
      initialAmountReceived
      wphgExpireDate
      wphg
      balanceClosed
      balanceClosedDate
      createdAt
      depotBalance
      depotItems
      cashoutPlan {
        id
        rate
        counter
        interval
        startDate
        endDate
        nextDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      liquidAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      depots {
        items {
          id
          number
          balance
          twr
          mwr
          profit
          depotItems {
            items {
              id
              name
              number
              quantity
              value
              valueCurrency
              einstandskurs
              aktuellerKurs
              notRealGUV
              notRealGUVCurrency
              notRealGUVPercentage
              referenceDate
              avaloqAssetId
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      conditions {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    condition {
      id
      advisor
      category
      strategy
      accountType
      minInvest
      minIncrease
      minDecrease
      minRate
      allowance
      cashoutplan
      savingsplan
      consulting
      feeQuirion
      feeAdvisor
      validFrom
      validTo
      homepageUrl
      conditionLink
      advisorConfirmText
      contact
      phoneNumber
      description
      descriptionTitle
      title
      name
      newBp
      newIps
      costProfile
      products {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
    }
    owner
    members
  }
}
`;
export const onCreateCondition = `subscription OnCreateCondition {
  onCreateCondition {
    id
    advisor
    category
    strategy
    accountType
    minInvest
    minIncrease
    minDecrease
    minRate
    allowance
    cashoutplan
    savingsplan
    consulting
    feeQuirion
    feeAdvisor
    validFrom
    validTo
    homepageUrl
    conditionLink
    advisorConfirmText
    contact
    phoneNumber
    description
    descriptionTitle
    title
    name
    newBp
    newIps
    costProfile
    products {
      items {
        id
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        condition {
          id
          advisor
          category
          strategy
          accountType
          minInvest
          minIncrease
          minDecrease
          minRate
          allowance
          cashoutplan
          savingsplan
          consulting
          feeQuirion
          feeAdvisor
          validFrom
          validTo
          homepageUrl
          conditionLink
          advisorConfirmText
          contact
          phoneNumber
          description
          descriptionTitle
          title
          name
          newBp
          newIps
          costProfile
          products {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
        }
        owner
        members
      }
      nextToken
    }
  }
}
`;
export const onUpdateCondition = `subscription OnUpdateCondition {
  onUpdateCondition {
    id
    advisor
    category
    strategy
    accountType
    minInvest
    minIncrease
    minDecrease
    minRate
    allowance
    cashoutplan
    savingsplan
    consulting
    feeQuirion
    feeAdvisor
    validFrom
    validTo
    homepageUrl
    conditionLink
    advisorConfirmText
    contact
    phoneNumber
    description
    descriptionTitle
    title
    name
    newBp
    newIps
    costProfile
    products {
      items {
        id
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        condition {
          id
          advisor
          category
          strategy
          accountType
          minInvest
          minIncrease
          minDecrease
          minRate
          allowance
          cashoutplan
          savingsplan
          consulting
          feeQuirion
          feeAdvisor
          validFrom
          validTo
          homepageUrl
          conditionLink
          advisorConfirmText
          contact
          phoneNumber
          description
          descriptionTitle
          title
          name
          newBp
          newIps
          costProfile
          products {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
        }
        owner
        members
      }
      nextToken
    }
  }
}
`;
export const onDeleteCondition = `subscription OnDeleteCondition {
  onDeleteCondition {
    id
    advisor
    category
    strategy
    accountType
    minInvest
    minIncrease
    minDecrease
    minRate
    allowance
    cashoutplan
    savingsplan
    consulting
    feeQuirion
    feeAdvisor
    validFrom
    validTo
    homepageUrl
    conditionLink
    advisorConfirmText
    contact
    phoneNumber
    description
    descriptionTitle
    title
    name
    newBp
    newIps
    costProfile
    products {
      items {
        id
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        condition {
          id
          advisor
          category
          strategy
          accountType
          minInvest
          minIncrease
          minDecrease
          minRate
          allowance
          cashoutplan
          savingsplan
          consulting
          feeQuirion
          feeAdvisor
          validFrom
          validTo
          homepageUrl
          conditionLink
          advisorConfirmText
          contact
          phoneNumber
          description
          descriptionTitle
          title
          name
          newBp
          newIps
          costProfile
          products {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
        }
        owner
        members
      }
      nextToken
    }
  }
}
`;
export const onCreateProductActionMap = `subscription OnCreateProductActionMap($owner: String, $members: String) {
  onCreateProductActionMap(owner: $owner, members: $members) {
    id
    action {
      id
      trigger
      dateFrom
      dateTo
      advisor
      category
      accountType
      existingCustomer
      newCustomer
      tasks
      name
      actionTCLink
      accept
      products {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    product {
      id
      number
      name
      category
      strategy
      recommendedStrategy
      recommendedName
      balanceActual
      balanceStart
      balanceDate
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      statusProduct
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      retirementYear
      stateMachineToken
      wphgInitialAmount
      initialAmountReceived
      wphgExpireDate
      wphg
      balanceClosed
      balanceClosedDate
      createdAt
      depotBalance
      depotItems
      cashoutPlan {
        id
        rate
        counter
        interval
        startDate
        endDate
        nextDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      liquidAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      depots {
        items {
          id
          number
          balance
          twr
          mwr
          profit
          depotItems {
            items {
              id
              name
              number
              quantity
              value
              valueCurrency
              einstandskurs
              aktuellerKurs
              notRealGUV
              notRealGUVCurrency
              notRealGUVPercentage
              referenceDate
              avaloqAssetId
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      conditions {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onUpdateProductActionMap = `subscription OnUpdateProductActionMap($owner: String, $members: String) {
  onUpdateProductActionMap(owner: $owner, members: $members) {
    id
    action {
      id
      trigger
      dateFrom
      dateTo
      advisor
      category
      accountType
      existingCustomer
      newCustomer
      tasks
      name
      actionTCLink
      accept
      products {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    product {
      id
      number
      name
      category
      strategy
      recommendedStrategy
      recommendedName
      balanceActual
      balanceStart
      balanceDate
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      statusProduct
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      retirementYear
      stateMachineToken
      wphgInitialAmount
      initialAmountReceived
      wphgExpireDate
      wphg
      balanceClosed
      balanceClosedDate
      createdAt
      depotBalance
      depotItems
      cashoutPlan {
        id
        rate
        counter
        interval
        startDate
        endDate
        nextDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      liquidAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      depots {
        items {
          id
          number
          balance
          twr
          mwr
          profit
          depotItems {
            items {
              id
              name
              number
              quantity
              value
              valueCurrency
              einstandskurs
              aktuellerKurs
              notRealGUV
              notRealGUVCurrency
              notRealGUVPercentage
              referenceDate
              avaloqAssetId
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      conditions {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onDeleteProductActionMap = `subscription OnDeleteProductActionMap($owner: String, $members: String) {
  onDeleteProductActionMap(owner: $owner, members: $members) {
    id
    action {
      id
      trigger
      dateFrom
      dateTo
      advisor
      category
      accountType
      existingCustomer
      newCustomer
      tasks
      name
      actionTCLink
      accept
      products {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    product {
      id
      number
      name
      category
      strategy
      recommendedStrategy
      recommendedName
      balanceActual
      balanceStart
      balanceDate
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      statusProduct
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      retirementYear
      stateMachineToken
      wphgInitialAmount
      initialAmountReceived
      wphgExpireDate
      wphg
      balanceClosed
      balanceClosedDate
      createdAt
      depotBalance
      depotItems
      cashoutPlan {
        id
        rate
        counter
        interval
        startDate
        endDate
        nextDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      liquidAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      depots {
        items {
          id
          number
          balance
          twr
          mwr
          profit
          depotItems {
            items {
              id
              name
              number
              quantity
              value
              valueCurrency
              einstandskurs
              aktuellerKurs
              notRealGUV
              notRealGUVCurrency
              notRealGUVPercentage
              referenceDate
              avaloqAssetId
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      conditions {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onCreateAction = `subscription OnCreateAction($owner: String, $members: String) {
  onCreateAction(owner: $owner, members: $members) {
    id
    trigger
    dateFrom
    dateTo
    advisor
    category
    accountType
    existingCustomer
    newCustomer
    tasks
    name
    actionTCLink
    accept
    products {
      items {
        id
        action {
          id
          trigger
          dateFrom
          dateTo
          advisor
          category
          accountType
          existingCustomer
          newCustomer
          tasks
          name
          actionTCLink
          accept
          products {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onUpdateAction = `subscription OnUpdateAction($owner: String, $members: String) {
  onUpdateAction(owner: $owner, members: $members) {
    id
    trigger
    dateFrom
    dateTo
    advisor
    category
    accountType
    existingCustomer
    newCustomer
    tasks
    name
    actionTCLink
    accept
    products {
      items {
        id
        action {
          id
          trigger
          dateFrom
          dateTo
          advisor
          category
          accountType
          existingCustomer
          newCustomer
          tasks
          name
          actionTCLink
          accept
          products {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onDeleteAction = `subscription OnDeleteAction($owner: String, $members: String) {
  onDeleteAction(owner: $owner, members: $members) {
    id
    trigger
    dateFrom
    dateTo
    advisor
    category
    accountType
    existingCustomer
    newCustomer
    tasks
    name
    actionTCLink
    accept
    products {
      items {
        id
        action {
          id
          trigger
          dateFrom
          dateTo
          advisor
          category
          accountType
          existingCustomer
          newCustomer
          tasks
          name
          actionTCLink
          accept
          products {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        product {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onCreateDepot = `subscription OnCreateDepot($owner: String, $members: String) {
  onCreateDepot(owner: $owner, members: $members) {
    id
    number
    balance
    twr
    mwr
    profit
    depotItems {
      items {
        id
        name
        number
        quantity
        value
        valueCurrency
        einstandskurs
        aktuellerKurs
        notRealGUV
        notRealGUVCurrency
        notRealGUVPercentage
        referenceDate
        avaloqAssetId
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onUpdateDepot = `subscription OnUpdateDepot($owner: String, $members: String) {
  onUpdateDepot(owner: $owner, members: $members) {
    id
    number
    balance
    twr
    mwr
    profit
    depotItems {
      items {
        id
        name
        number
        quantity
        value
        valueCurrency
        einstandskurs
        aktuellerKurs
        notRealGUV
        notRealGUVCurrency
        notRealGUVPercentage
        referenceDate
        avaloqAssetId
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onDeleteDepot = `subscription OnDeleteDepot($owner: String, $members: String) {
  onDeleteDepot(owner: $owner, members: $members) {
    id
    number
    balance
    twr
    mwr
    profit
    depotItems {
      items {
        id
        name
        number
        quantity
        value
        valueCurrency
        einstandskurs
        aktuellerKurs
        notRealGUV
        notRealGUVCurrency
        notRealGUVPercentage
        referenceDate
        avaloqAssetId
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onCreateDepotItem = `subscription OnCreateDepotItem($owner: String, $members: String) {
  onCreateDepotItem(owner: $owner, members: $members) {
    id
    name
    number
    quantity
    value
    valueCurrency
    einstandskurs
    aktuellerKurs
    notRealGUV
    notRealGUVCurrency
    notRealGUVPercentage
    referenceDate
    avaloqAssetId
    owner
    members
  }
}
`;
export const onUpdateDepotItem = `subscription OnUpdateDepotItem($owner: String, $members: String) {
  onUpdateDepotItem(owner: $owner, members: $members) {
    id
    name
    number
    quantity
    value
    valueCurrency
    einstandskurs
    aktuellerKurs
    notRealGUV
    notRealGUVCurrency
    notRealGUVPercentage
    referenceDate
    avaloqAssetId
    owner
    members
  }
}
`;
export const onDeleteDepotItem = `subscription OnDeleteDepotItem($owner: String, $members: String) {
  onDeleteDepotItem(owner: $owner, members: $members) {
    id
    name
    number
    quantity
    value
    valueCurrency
    einstandskurs
    aktuellerKurs
    notRealGUV
    notRealGUVCurrency
    notRealGUVPercentage
    referenceDate
    avaloqAssetId
    owner
    members
  }
}
`;
export const onCreateAsset = `subscription OnCreateAsset {
  onCreateAsset {
    id
    isin
    name
    colorCode
    kiid
    class
    region
    category
    riskClass
    rate
    currency
    exchangeRate
    dateLastRate
    shortName
    avaloqAssetId
    assetClass1
    assetClass2
    ter
    depotItems {
      items {
        id
        name
        number
        quantity
        value
        valueCurrency
        einstandskurs
        aktuellerKurs
        notRealGUV
        notRealGUVCurrency
        notRealGUVPercentage
        referenceDate
        avaloqAssetId
        owner
        members
      }
      nextToken
    }
  }
}
`;
export const onUpdateAsset = `subscription OnUpdateAsset {
  onUpdateAsset {
    id
    isin
    name
    colorCode
    kiid
    class
    region
    category
    riskClass
    rate
    currency
    exchangeRate
    dateLastRate
    shortName
    avaloqAssetId
    assetClass1
    assetClass2
    ter
    depotItems {
      items {
        id
        name
        number
        quantity
        value
        valueCurrency
        einstandskurs
        aktuellerKurs
        notRealGUV
        notRealGUVCurrency
        notRealGUVPercentage
        referenceDate
        avaloqAssetId
        owner
        members
      }
      nextToken
    }
  }
}
`;
export const onDeleteAsset = `subscription OnDeleteAsset {
  onDeleteAsset {
    id
    isin
    name
    colorCode
    kiid
    class
    region
    category
    riskClass
    rate
    currency
    exchangeRate
    dateLastRate
    shortName
    avaloqAssetId
    assetClass1
    assetClass2
    ter
    depotItems {
      items {
        id
        name
        number
        quantity
        value
        valueCurrency
        einstandskurs
        aktuellerKurs
        notRealGUV
        notRealGUVCurrency
        notRealGUVPercentage
        referenceDate
        avaloqAssetId
        owner
        members
      }
      nextToken
    }
  }
}
`;
export const onCreateSavingsPlan = `subscription OnCreateSavingsPlan($owner: String, $members: String) {
  onCreateSavingsPlan(owner: $owner, members: $members) {
    id
    name
    rate
    startDate
    endDate
    nextDate
    executionDay
    counter
    interval
    mandat
    mandatSignatureDate
    bankAccount {
      id
      number
      name
      accountHolder
      iban
      bic
      currency
      exchangeRate
      accountType
      balance
      balanceEur
      reference
      eod
      eodDate
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      transactions {
        items {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onUpdateSavingsPlan = `subscription OnUpdateSavingsPlan($owner: String, $members: String) {
  onUpdateSavingsPlan(owner: $owner, members: $members) {
    id
    name
    rate
    startDate
    endDate
    nextDate
    executionDay
    counter
    interval
    mandat
    mandatSignatureDate
    bankAccount {
      id
      number
      name
      accountHolder
      iban
      bic
      currency
      exchangeRate
      accountType
      balance
      balanceEur
      reference
      eod
      eodDate
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      transactions {
        items {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onDeleteSavingsPlan = `subscription OnDeleteSavingsPlan($owner: String, $members: String) {
  onDeleteSavingsPlan(owner: $owner, members: $members) {
    id
    name
    rate
    startDate
    endDate
    nextDate
    executionDay
    counter
    interval
    mandat
    mandatSignatureDate
    bankAccount {
      id
      number
      name
      accountHolder
      iban
      bic
      currency
      exchangeRate
      accountType
      balance
      balanceEur
      reference
      eod
      eodDate
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      transactions {
        items {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onCreateCashoutPlan = `subscription OnCreateCashoutPlan($owner: String, $members: String) {
  onCreateCashoutPlan(owner: $owner, members: $members) {
    id
    rate
    counter
    interval
    startDate
    endDate
    nextDate
    bankAccount {
      id
      number
      name
      accountHolder
      iban
      bic
      currency
      exchangeRate
      accountType
      balance
      balanceEur
      reference
      eod
      eodDate
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      transactions {
        items {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onUpdateCashoutPlan = `subscription OnUpdateCashoutPlan($owner: String, $members: String) {
  onUpdateCashoutPlan(owner: $owner, members: $members) {
    id
    rate
    counter
    interval
    startDate
    endDate
    nextDate
    bankAccount {
      id
      number
      name
      accountHolder
      iban
      bic
      currency
      exchangeRate
      accountType
      balance
      balanceEur
      reference
      eod
      eodDate
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      transactions {
        items {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onDeleteCashoutPlan = `subscription OnDeleteCashoutPlan($owner: String, $members: String) {
  onDeleteCashoutPlan(owner: $owner, members: $members) {
    id
    rate
    counter
    interval
    startDate
    endDate
    nextDate
    bankAccount {
      id
      number
      name
      accountHolder
      iban
      bic
      currency
      exchangeRate
      accountType
      balance
      balanceEur
      reference
      eod
      eodDate
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      transactions {
        items {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onCreateBankAccount = `subscription OnCreateBankAccount($owner: String, $members: String) {
  onCreateBankAccount(owner: $owner, members: $members) {
    id
    number
    name
    accountHolder
    iban
    bic
    currency
    exchangeRate
    accountType
    balance
    balanceEur
    reference
    eod
    eodDate
    savingsPlans {
      items {
        id
        name
        rate
        startDate
        endDate
        nextDate
        executionDay
        counter
        interval
        mandat
        mandatSignatureDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    transactions {
      items {
        id
        number
        amount
        currency
        valutaDate
        bookingDate
        sequenceNumber
        ktoText
        mandat
        mandatSignatureDate
        auftrag
        metaTyp
        auftragsArt
        ereignis
        ereignisStatus
        internalBookingText
        externalBookingText
        status
        createdAt
        updatedAt
        sfType
        sfARN
        stateMachineToken
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onUpdateBankAccount = `subscription OnUpdateBankAccount($owner: String, $members: String) {
  onUpdateBankAccount(owner: $owner, members: $members) {
    id
    number
    name
    accountHolder
    iban
    bic
    currency
    exchangeRate
    accountType
    balance
    balanceEur
    reference
    eod
    eodDate
    savingsPlans {
      items {
        id
        name
        rate
        startDate
        endDate
        nextDate
        executionDay
        counter
        interval
        mandat
        mandatSignatureDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    transactions {
      items {
        id
        number
        amount
        currency
        valutaDate
        bookingDate
        sequenceNumber
        ktoText
        mandat
        mandatSignatureDate
        auftrag
        metaTyp
        auftragsArt
        ereignis
        ereignisStatus
        internalBookingText
        externalBookingText
        status
        createdAt
        updatedAt
        sfType
        sfARN
        stateMachineToken
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onDeleteBankAccount = `subscription OnDeleteBankAccount($owner: String, $members: String) {
  onDeleteBankAccount(owner: $owner, members: $members) {
    id
    number
    name
    accountHolder
    iban
    bic
    currency
    exchangeRate
    accountType
    balance
    balanceEur
    reference
    eod
    eodDate
    savingsPlans {
      items {
        id
        name
        rate
        startDate
        endDate
        nextDate
        executionDay
        counter
        interval
        mandat
        mandatSignatureDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    transactions {
      items {
        id
        number
        amount
        currency
        valutaDate
        bookingDate
        sequenceNumber
        ktoText
        mandat
        mandatSignatureDate
        auftrag
        metaTyp
        auftragsArt
        ereignis
        ereignisStatus
        internalBookingText
        externalBookingText
        status
        createdAt
        updatedAt
        sfType
        sfARN
        stateMachineToken
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onCreateTransactionPending = `subscription OnCreateTransactionPending($owner: String, $members: String) {
  onCreateTransactionPending(owner: $owner, members: $members) {
    id
    number
    ktoText
    mandat
    amount
    currency
    status
    mandatSignatureDate
    createdAt
    sfType
    sfARN
    stateMachineToken
    productId
    businessPartnerId
    owner
    members
  }
}
`;
export const onUpdateTransactionPending = `subscription OnUpdateTransactionPending($owner: String, $members: String) {
  onUpdateTransactionPending(owner: $owner, members: $members) {
    id
    number
    ktoText
    mandat
    amount
    currency
    status
    mandatSignatureDate
    createdAt
    sfType
    sfARN
    stateMachineToken
    productId
    businessPartnerId
    owner
    members
  }
}
`;
export const onDeleteTransactionPending = `subscription OnDeleteTransactionPending($owner: String, $members: String) {
  onDeleteTransactionPending(owner: $owner, members: $members) {
    id
    number
    ktoText
    mandat
    amount
    currency
    status
    mandatSignatureDate
    createdAt
    sfType
    sfARN
    stateMachineToken
    productId
    businessPartnerId
    owner
    members
  }
}
`;
export const onCreateTransaction = `subscription OnCreateTransaction($owner: String, $members: String) {
  onCreateTransaction(owner: $owner, members: $members) {
    id
    number
    amount
    currency
    valutaDate
    bookingDate
    sequenceNumber
    ktoText
    mandat
    mandatSignatureDate
    auftrag
    metaTyp
    auftragsArt
    ereignis
    ereignisStatus
    internalBookingText
    externalBookingText
    status
    createdAt
    updatedAt
    sfType
    sfARN
    stateMachineToken
    bankAccount {
      id
      number
      name
      accountHolder
      iban
      bic
      currency
      exchangeRate
      accountType
      balance
      balanceEur
      reference
      eod
      eodDate
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      transactions {
        items {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onUpdateTransaction = `subscription OnUpdateTransaction($owner: String, $members: String) {
  onUpdateTransaction(owner: $owner, members: $members) {
    id
    number
    amount
    currency
    valutaDate
    bookingDate
    sequenceNumber
    ktoText
    mandat
    mandatSignatureDate
    auftrag
    metaTyp
    auftragsArt
    ereignis
    ereignisStatus
    internalBookingText
    externalBookingText
    status
    createdAt
    updatedAt
    sfType
    sfARN
    stateMachineToken
    bankAccount {
      id
      number
      name
      accountHolder
      iban
      bic
      currency
      exchangeRate
      accountType
      balance
      balanceEur
      reference
      eod
      eodDate
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      transactions {
        items {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onDeleteTransaction = `subscription OnDeleteTransaction($owner: String, $members: String) {
  onDeleteTransaction(owner: $owner, members: $members) {
    id
    number
    amount
    currency
    valutaDate
    bookingDate
    sequenceNumber
    ktoText
    mandat
    mandatSignatureDate
    auftrag
    metaTyp
    auftragsArt
    ereignis
    ereignisStatus
    internalBookingText
    externalBookingText
    status
    createdAt
    updatedAt
    sfType
    sfARN
    stateMachineToken
    bankAccount {
      id
      number
      name
      accountHolder
      iban
      bic
      currency
      exchangeRate
      accountType
      balance
      balanceEur
      reference
      eod
      eodDate
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      transactions {
        items {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onCreatePostbox = `subscription OnCreatePostbox($owner: String, $members: String) {
  onCreatePostbox(owner: $owner, members: $members) {
    id
    title
    date
    readTime
    status
    documentKey
    searchField
    person {
      id
      eMail
      externalId
      avaloqId
      postIdent
      communicationEMail
      communicationEMailVerified
      dataComplete
      needToMigrateDoc
      identityId
      gender
      salutation
      title
      titleAdd
      firstName
      middleName
      lastName
      phoneNumber
      mobilePhoneNumber
      passportNumber
      passportIssuer
      passportIssuerDate
      passportValidToDate
      passportPlaceOfIssue
      passportCountryOfIssuer
      birthCertificateNumber
      birthCertificateIssuer
      birthCertificateIssuerDate
      birthCertificateCountryOfIssuer
      birthCertificateInitialEMail
      nationality
      street
      streetNumber
      zip
      city
      state
      country
      addressType
      maritalStatus
      taxNumber
      birthday
      birthplace
      nativeCountry
      occupation
      occupationIndustry
      taxOnlyInGermany
      additionalTax
      mmw
      campaign
      postIdentCaseId
      identificationMethod
      identificationStatus
      identificationType
      utmSource
      utmMedium
      utmCampaign
      utmData
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
      businessPartners {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      postbox {
        items {
          id
          title
          date
          readTime
          status
          documentKey
          searchField
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onUpdatePostbox = `subscription OnUpdatePostbox($owner: String, $members: String) {
  onUpdatePostbox(owner: $owner, members: $members) {
    id
    title
    date
    readTime
    status
    documentKey
    searchField
    person {
      id
      eMail
      externalId
      avaloqId
      postIdent
      communicationEMail
      communicationEMailVerified
      dataComplete
      needToMigrateDoc
      identityId
      gender
      salutation
      title
      titleAdd
      firstName
      middleName
      lastName
      phoneNumber
      mobilePhoneNumber
      passportNumber
      passportIssuer
      passportIssuerDate
      passportValidToDate
      passportPlaceOfIssue
      passportCountryOfIssuer
      birthCertificateNumber
      birthCertificateIssuer
      birthCertificateIssuerDate
      birthCertificateCountryOfIssuer
      birthCertificateInitialEMail
      nationality
      street
      streetNumber
      zip
      city
      state
      country
      addressType
      maritalStatus
      taxNumber
      birthday
      birthplace
      nativeCountry
      occupation
      occupationIndustry
      taxOnlyInGermany
      additionalTax
      mmw
      campaign
      postIdentCaseId
      identificationMethod
      identificationStatus
      identificationType
      utmSource
      utmMedium
      utmCampaign
      utmData
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
      businessPartners {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      postbox {
        items {
          id
          title
          date
          readTime
          status
          documentKey
          searchField
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onDeletePostbox = `subscription OnDeletePostbox($owner: String, $members: String) {
  onDeletePostbox(owner: $owner, members: $members) {
    id
    title
    date
    readTime
    status
    documentKey
    searchField
    person {
      id
      eMail
      externalId
      avaloqId
      postIdent
      communicationEMail
      communicationEMailVerified
      dataComplete
      needToMigrateDoc
      identityId
      gender
      salutation
      title
      titleAdd
      firstName
      middleName
      lastName
      phoneNumber
      mobilePhoneNumber
      passportNumber
      passportIssuer
      passportIssuerDate
      passportValidToDate
      passportPlaceOfIssue
      passportCountryOfIssuer
      birthCertificateNumber
      birthCertificateIssuer
      birthCertificateIssuerDate
      birthCertificateCountryOfIssuer
      birthCertificateInitialEMail
      nationality
      street
      streetNumber
      zip
      city
      state
      country
      addressType
      maritalStatus
      taxNumber
      birthday
      birthplace
      nativeCountry
      occupation
      occupationIndustry
      taxOnlyInGermany
      additionalTax
      mmw
      campaign
      postIdentCaseId
      identificationMethod
      identificationStatus
      identificationType
      utmSource
      utmMedium
      utmCampaign
      utmData
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
      businessPartners {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      postbox {
        items {
          id
          title
          date
          readTime
          status
          documentKey
          searchField
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onCreateOrder = `subscription OnCreateOrder($owner: String, $members: String) {
  onCreateOrder(owner: $owner, members: $members) {
    id
    productId
    businessPartnerId
    personId
    amount
    sfType
    sfARN
    sfStartDate
    status
    createdAt
    EndOfStateMachine
    stateMachineToken
    product {
      id
      number
      name
      category
      strategy
      recommendedStrategy
      recommendedName
      balanceActual
      balanceStart
      balanceDate
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      statusProduct
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      retirementYear
      stateMachineToken
      wphgInitialAmount
      initialAmountReceived
      wphgExpireDate
      wphg
      balanceClosed
      balanceClosedDate
      createdAt
      depotBalance
      depotItems
      cashoutPlan {
        id
        rate
        counter
        interval
        startDate
        endDate
        nextDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      liquidAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      depots {
        items {
          id
          number
          balance
          twr
          mwr
          profit
          depotItems {
            items {
              id
              name
              number
              quantity
              value
              valueCurrency
              einstandskurs
              aktuellerKurs
              notRealGUV
              notRealGUVCurrency
              notRealGUVPercentage
              referenceDate
              avaloqAssetId
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      conditions {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    businessPartner {
      id
      number
      accountType
      name
      statusContract
      avaloqSync
      advisor
      branch
      balanceActual
      balanceDate
      balanceStart
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      joinedMember
      childMember
      otherMembers
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      stateMachineToken
      createdAt
      openedAt
      closedAt
      wphgExpireDate
      wphg
      businessPartnerCode {
        items {
          id
          attorney
          businessPartnerId
          accountCode
          accountCodeStatus
          acceptedByOwner
          owner
          members
        }
        nextToken
      }
      persons {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      products {
        items {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      bankAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    person {
      id
      eMail
      externalId
      avaloqId
      postIdent
      communicationEMail
      communicationEMailVerified
      dataComplete
      needToMigrateDoc
      identityId
      gender
      salutation
      title
      titleAdd
      firstName
      middleName
      lastName
      phoneNumber
      mobilePhoneNumber
      passportNumber
      passportIssuer
      passportIssuerDate
      passportValidToDate
      passportPlaceOfIssue
      passportCountryOfIssuer
      birthCertificateNumber
      birthCertificateIssuer
      birthCertificateIssuerDate
      birthCertificateCountryOfIssuer
      birthCertificateInitialEMail
      nationality
      street
      streetNumber
      zip
      city
      state
      country
      addressType
      maritalStatus
      taxNumber
      birthday
      birthplace
      nativeCountry
      occupation
      occupationIndustry
      taxOnlyInGermany
      additionalTax
      mmw
      campaign
      postIdentCaseId
      identificationMethod
      identificationStatus
      identificationType
      utmSource
      utmMedium
      utmCampaign
      utmData
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
      businessPartners {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      postbox {
        items {
          id
          title
          date
          readTime
          status
          documentKey
          searchField
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    subOrder {
      items {
        id
        chain
        amount
        status
        type
        createdAt
        transaction {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        order {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onUpdateOrder = `subscription OnUpdateOrder($owner: String, $members: String) {
  onUpdateOrder(owner: $owner, members: $members) {
    id
    productId
    businessPartnerId
    personId
    amount
    sfType
    sfARN
    sfStartDate
    status
    createdAt
    EndOfStateMachine
    stateMachineToken
    product {
      id
      number
      name
      category
      strategy
      recommendedStrategy
      recommendedName
      balanceActual
      balanceStart
      balanceDate
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      statusProduct
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      retirementYear
      stateMachineToken
      wphgInitialAmount
      initialAmountReceived
      wphgExpireDate
      wphg
      balanceClosed
      balanceClosedDate
      createdAt
      depotBalance
      depotItems
      cashoutPlan {
        id
        rate
        counter
        interval
        startDate
        endDate
        nextDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      liquidAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      depots {
        items {
          id
          number
          balance
          twr
          mwr
          profit
          depotItems {
            items {
              id
              name
              number
              quantity
              value
              valueCurrency
              einstandskurs
              aktuellerKurs
              notRealGUV
              notRealGUVCurrency
              notRealGUVPercentage
              referenceDate
              avaloqAssetId
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      conditions {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    businessPartner {
      id
      number
      accountType
      name
      statusContract
      avaloqSync
      advisor
      branch
      balanceActual
      balanceDate
      balanceStart
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      joinedMember
      childMember
      otherMembers
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      stateMachineToken
      createdAt
      openedAt
      closedAt
      wphgExpireDate
      wphg
      businessPartnerCode {
        items {
          id
          attorney
          businessPartnerId
          accountCode
          accountCodeStatus
          acceptedByOwner
          owner
          members
        }
        nextToken
      }
      persons {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      products {
        items {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      bankAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    person {
      id
      eMail
      externalId
      avaloqId
      postIdent
      communicationEMail
      communicationEMailVerified
      dataComplete
      needToMigrateDoc
      identityId
      gender
      salutation
      title
      titleAdd
      firstName
      middleName
      lastName
      phoneNumber
      mobilePhoneNumber
      passportNumber
      passportIssuer
      passportIssuerDate
      passportValidToDate
      passportPlaceOfIssue
      passportCountryOfIssuer
      birthCertificateNumber
      birthCertificateIssuer
      birthCertificateIssuerDate
      birthCertificateCountryOfIssuer
      birthCertificateInitialEMail
      nationality
      street
      streetNumber
      zip
      city
      state
      country
      addressType
      maritalStatus
      taxNumber
      birthday
      birthplace
      nativeCountry
      occupation
      occupationIndustry
      taxOnlyInGermany
      additionalTax
      mmw
      campaign
      postIdentCaseId
      identificationMethod
      identificationStatus
      identificationType
      utmSource
      utmMedium
      utmCampaign
      utmData
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
      businessPartners {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      postbox {
        items {
          id
          title
          date
          readTime
          status
          documentKey
          searchField
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    subOrder {
      items {
        id
        chain
        amount
        status
        type
        createdAt
        transaction {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        order {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onDeleteOrder = `subscription OnDeleteOrder($owner: String, $members: String) {
  onDeleteOrder(owner: $owner, members: $members) {
    id
    productId
    businessPartnerId
    personId
    amount
    sfType
    sfARN
    sfStartDate
    status
    createdAt
    EndOfStateMachine
    stateMachineToken
    product {
      id
      number
      name
      category
      strategy
      recommendedStrategy
      recommendedName
      balanceActual
      balanceStart
      balanceDate
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      statusProduct
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      retirementYear
      stateMachineToken
      wphgInitialAmount
      initialAmountReceived
      wphgExpireDate
      wphg
      balanceClosed
      balanceClosedDate
      createdAt
      depotBalance
      depotItems
      cashoutPlan {
        id
        rate
        counter
        interval
        startDate
        endDate
        nextDate
        bankAccount {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      liquidAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      savingsPlans {
        items {
          id
          name
          rate
          startDate
          endDate
          nextDate
          executionDay
          counter
          interval
          mandat
          mandatSignatureDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      depots {
        items {
          id
          number
          balance
          twr
          mwr
          profit
          depotItems {
            items {
              id
              name
              number
              quantity
              value
              valueCurrency
              einstandskurs
              aktuellerKurs
              notRealGUV
              notRealGUVCurrency
              notRealGUVPercentage
              referenceDate
              avaloqAssetId
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      conditions {
        items {
          id
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          condition {
            id
            advisor
            category
            strategy
            accountType
            minInvest
            minIncrease
            minDecrease
            minRate
            allowance
            cashoutplan
            savingsplan
            consulting
            feeQuirion
            feeAdvisor
            validFrom
            validTo
            homepageUrl
            conditionLink
            advisorConfirmText
            contact
            phoneNumber
            description
            descriptionTitle
            title
            name
            newBp
            newIps
            costProfile
            products {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
          }
          owner
          members
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            trigger
            dateFrom
            dateTo
            advisor
            category
            accountType
            existingCustomer
            newCustomer
            tasks
            name
            actionTCLink
            accept
            products {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    businessPartner {
      id
      number
      accountType
      name
      statusContract
      avaloqSync
      advisor
      branch
      balanceActual
      balanceDate
      balanceStart
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      joinedMember
      childMember
      otherMembers
      portfolioHistory
      portfolioHistoryYear
      performanceStartDate
      stateMachineToken
      createdAt
      openedAt
      closedAt
      wphgExpireDate
      wphg
      businessPartnerCode {
        items {
          id
          attorney
          businessPartnerId
          accountCode
          accountCodeStatus
          acceptedByOwner
          owner
          members
        }
        nextToken
      }
      persons {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      products {
        items {
          id
          number
          name
          category
          strategy
          recommendedStrategy
          recommendedName
          balanceActual
          balanceStart
          balanceDate
          totalChangeBegin
          totalChangeYearStart
          twrPerformanceStart
          twrPerformanceYearStart
          mwrPerformanceStart
          mwrPerformanceYearStart
          statusProduct
          portfolioHistory
          portfolioHistoryYear
          performanceStartDate
          retirementYear
          stateMachineToken
          wphgInitialAmount
          initialAmountReceived
          wphgExpireDate
          wphg
          balanceClosed
          balanceClosedDate
          createdAt
          depotBalance
          depotItems
          cashoutPlan {
            id
            rate
            counter
            interval
            startDate
            endDate
            nextDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          liquidAccounts {
            items {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          depots {
            items {
              id
              number
              balance
              twr
              mwr
              profit
              depotItems {
                items {
                  id
                  name
                  number
                  quantity
                  value
                  valueCurrency
                  einstandskurs
                  aktuellerKurs
                  notRealGUV
                  notRealGUVCurrency
                  notRealGUVPercentage
                  referenceDate
                  avaloqAssetId
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          orders {
            items {
              id
              productId
              businessPartnerId
              personId
              amount
              sfType
              sfARN
              sfStartDate
              status
              createdAt
              EndOfStateMachine
              stateMachineToken
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              businessPartner {
                id
                number
                accountType
                name
                statusContract
                avaloqSync
                advisor
                branch
                balanceActual
                balanceDate
                balanceStart
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                joinedMember
                childMember
                otherMembers
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                stateMachineToken
                createdAt
                openedAt
                closedAt
                wphgExpireDate
                wphg
                businessPartnerCode {
                  items {
                    id
                    attorney
                    businessPartnerId
                    accountCode
                    accountCodeStatus
                    acceptedByOwner
                    owner
                    members
                  }
                  nextToken
                }
                persons {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                products {
                  items {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                bankAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              person {
                id
                eMail
                externalId
                avaloqId
                postIdent
                communicationEMail
                communicationEMailVerified
                dataComplete
                needToMigrateDoc
                identityId
                gender
                salutation
                title
                titleAdd
                firstName
                middleName
                lastName
                phoneNumber
                mobilePhoneNumber
                passportNumber
                passportIssuer
                passportIssuerDate
                passportValidToDate
                passportPlaceOfIssue
                passportCountryOfIssuer
                birthCertificateNumber
                birthCertificateIssuer
                birthCertificateIssuerDate
                birthCertificateCountryOfIssuer
                birthCertificateInitialEMail
                nationality
                street
                streetNumber
                zip
                city
                state
                country
                addressType
                maritalStatus
                taxNumber
                birthday
                birthplace
                nativeCountry
                occupation
                occupationIndustry
                taxOnlyInGermany
                additionalTax
                mmw
                campaign
                postIdentCaseId
                identificationMethod
                identificationStatus
                identificationType
                utmSource
                utmMedium
                utmCampaign
                utmData
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
                businessPartners {
                  items {
                    id
                    role
                    waitForCode
                    waitForBirthCertificate
                    bpId
                    createdAt
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                postbox {
                  items {
                    id
                    title
                    date
                    readTime
                    status
                    documentKey
                    searchField
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                consultant {
                  id
                  branch
                  branchStreet
                  branchZip
                  branchCity
                  branchState
                  counter
                  firstName
                  lastName
                  email
                  phoneNumber
                  mobilePhoneNumber
                  status
                  s3BucketPicLink
                  createdAt
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                }
                owner
                members
              }
              subOrder {
                items {
                  id
                  chain
                  amount
                  status
                  type
                  createdAt
                  transaction {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  order {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            nextToken
          }
          conditions {
            items {
              id
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              condition {
                id
                advisor
                category
                strategy
                accountType
                minInvest
                minIncrease
                minDecrease
                minRate
                allowance
                cashoutplan
                savingsplan
                consulting
                feeQuirion
                feeAdvisor
                validFrom
                validTo
                homepageUrl
                conditionLink
                advisorConfirmText
                contact
                phoneNumber
                description
                descriptionTitle
                title
                name
                newBp
                newIps
                costProfile
                products {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
              }
              owner
              members
            }
            nextToken
          }
          actions {
            items {
              id
              action {
                id
                trigger
                dateFrom
                dateTo
                advisor
                category
                accountType
                existingCustomer
                newCustomer
                tasks
                name
                actionTCLink
                accept
                products {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              product {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      bankAccounts {
        items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
          eod
          eodDate
          savingsPlans {
            items {
              id
              name
              rate
              startDate
              endDate
              nextDate
              executionDay
              counter
              interval
              mandat
              mandatSignatureDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          transactions {
            items {
              id
              number
              amount
              currency
              valutaDate
              bookingDate
              sequenceNumber
              ktoText
              mandat
              mandatSignatureDate
              auftrag
              metaTyp
              auftragsArt
              ereignis
              ereignisStatus
              internalBookingText
              externalBookingText
              status
              createdAt
              updatedAt
              sfType
              sfARN
              stateMachineToken
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    person {
      id
      eMail
      externalId
      avaloqId
      postIdent
      communicationEMail
      communicationEMailVerified
      dataComplete
      needToMigrateDoc
      identityId
      gender
      salutation
      title
      titleAdd
      firstName
      middleName
      lastName
      phoneNumber
      mobilePhoneNumber
      passportNumber
      passportIssuer
      passportIssuerDate
      passportValidToDate
      passportPlaceOfIssue
      passportCountryOfIssuer
      birthCertificateNumber
      birthCertificateIssuer
      birthCertificateIssuerDate
      birthCertificateCountryOfIssuer
      birthCertificateInitialEMail
      nationality
      street
      streetNumber
      zip
      city
      state
      country
      addressType
      maritalStatus
      taxNumber
      birthday
      birthplace
      nativeCountry
      occupation
      occupationIndustry
      taxOnlyInGermany
      additionalTax
      mmw
      campaign
      postIdentCaseId
      identificationMethod
      identificationStatus
      identificationType
      utmSource
      utmMedium
      utmCampaign
      utmData
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
      businessPartners {
        items {
          id
          role
          waitForCode
          waitForBirthCertificate
          bpId
          createdAt
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      postbox {
        items {
          id
          title
          date
          readTime
          status
          documentKey
          searchField
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      orders {
        items {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        nextToken
      }
      consultant {
        id
        branch
        branchStreet
        branchZip
        branchCity
        branchState
        counter
        firstName
        lastName
        email
        phoneNumber
        mobilePhoneNumber
        status
        s3BucketPicLink
        createdAt
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
      }
      owner
      members
    }
    subOrder {
      items {
        id
        chain
        amount
        status
        type
        createdAt
        transaction {
          id
          number
          amount
          currency
          valutaDate
          bookingDate
          sequenceNumber
          ktoText
          mandat
          mandatSignatureDate
          auftrag
          metaTyp
          auftragsArt
          ereignis
          ereignisStatus
          internalBookingText
          externalBookingText
          status
          createdAt
          updatedAt
          sfType
          sfARN
          stateMachineToken
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        order {
          id
          productId
          businessPartnerId
          personId
          amount
          sfType
          sfARN
          sfStartDate
          status
          createdAt
          EndOfStateMachine
          stateMachineToken
          product {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          businessPartner {
            id
            number
            accountType
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceDate
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            stateMachineToken
            createdAt
            openedAt
            closedAt
            wphgExpireDate
            wphg
            businessPartnerCode {
              items {
                id
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
                owner
                members
              }
              nextToken
            }
            persons {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            products {
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                stateMachineToken
                wphgInitialAmount
                initialAmountReceived
                wphgExpireDate
                wphg
                balanceClosed
                balanceClosedDate
                createdAt
                depotBalance
                depotItems
                cashoutPlan {
                  id
                  rate
                  counter
                  interval
                  startDate
                  endDate
                  nextDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                liquidAccounts {
                  items {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                depots {
                  items {
                    id
                    number
                    balance
                    twr
                    mwr
                    profit
                    depotItems {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                orders {
                  items {
                    id
                    productId
                    businessPartnerId
                    personId
                    amount
                    sfType
                    sfARN
                    sfStartDate
                    status
                    createdAt
                    EndOfStateMachine
                    stateMachineToken
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    businessPartner {
                      id
                      number
                      accountType
                      name
                      statusContract
                      avaloqSync
                      advisor
                      branch
                      balanceActual
                      balanceDate
                      balanceStart
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      joinedMember
                      childMember
                      otherMembers
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      stateMachineToken
                      createdAt
                      openedAt
                      closedAt
                      wphgExpireDate
                      wphg
                      owner
                      members
                    }
                    person {
                      id
                      eMail
                      externalId
                      avaloqId
                      postIdent
                      communicationEMail
                      communicationEMailVerified
                      dataComplete
                      needToMigrateDoc
                      identityId
                      gender
                      salutation
                      title
                      titleAdd
                      firstName
                      middleName
                      lastName
                      phoneNumber
                      mobilePhoneNumber
                      passportNumber
                      passportIssuer
                      passportIssuerDate
                      passportValidToDate
                      passportPlaceOfIssue
                      passportCountryOfIssuer
                      birthCertificateNumber
                      birthCertificateIssuer
                      birthCertificateIssuerDate
                      birthCertificateCountryOfIssuer
                      birthCertificateInitialEMail
                      nationality
                      street
                      streetNumber
                      zip
                      city
                      state
                      country
                      addressType
                      maritalStatus
                      taxNumber
                      birthday
                      birthplace
                      nativeCountry
                      occupation
                      occupationIndustry
                      taxOnlyInGermany
                      additionalTax
                      mmw
                      campaign
                      postIdentCaseId
                      identificationMethod
                      identificationStatus
                      identificationType
                      utmSource
                      utmMedium
                      utmCampaign
                      utmData
                      owner
                      members
                    }
                    subOrder {
                      nextToken
                    }
                    owner
                    members
                  }
                  nextToken
                }
                conditions {
                  items {
                    id
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    condition {
                      id
                      advisor
                      category
                      strategy
                      accountType
                      minInvest
                      minIncrease
                      minDecrease
                      minRate
                      allowance
                      cashoutplan
                      savingsplan
                      consulting
                      feeQuirion
                      feeAdvisor
                      validFrom
                      validTo
                      homepageUrl
                      conditionLink
                      advisorConfirmText
                      contact
                      phoneNumber
                      description
                      descriptionTitle
                      title
                      name
                      newBp
                      newIps
                      costProfile
                    }
                    owner
                    members
                  }
                  nextToken
                }
                actions {
                  items {
                    id
                    action {
                      id
                      trigger
                      dateFrom
                      dateTo
                      advisor
                      category
                      accountType
                      existingCustomer
                      newCustomer
                      tasks
                      name
                      actionTCLink
                      accept
                      owner
                      members
                    }
                    product {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            bankAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          person {
            id
            eMail
            externalId
            avaloqId
            postIdent
            communicationEMail
            communicationEMailVerified
            dataComplete
            needToMigrateDoc
            identityId
            gender
            salutation
            title
            titleAdd
            firstName
            middleName
            lastName
            phoneNumber
            mobilePhoneNumber
            passportNumber
            passportIssuer
            passportIssuerDate
            passportValidToDate
            passportPlaceOfIssue
            passportCountryOfIssuer
            birthCertificateNumber
            birthCertificateIssuer
            birthCertificateIssuerDate
            birthCertificateCountryOfIssuer
            birthCertificateInitialEMail
            nationality
            street
            streetNumber
            zip
            city
            state
            country
            addressType
            maritalStatus
            taxNumber
            birthday
            birthplace
            nativeCountry
            occupation
            occupationIndustry
            taxOnlyInGermany
            additionalTax
            mmw
            campaign
            postIdentCaseId
            identificationMethod
            identificationStatus
            identificationType
            utmSource
            utmMedium
            utmCampaign
            utmData
            ndl {
              id
              name
              city
              zip
              street
              streetNr
            }
            businessPartners {
              items {
                id
                role
                waitForCode
                waitForBirthCertificate
                bpId
                createdAt
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            postbox {
              items {
                id
                title
                date
                readTime
                status
                documentKey
                searchField
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            consultant {
              id
              branch
              branchStreet
              branchZip
              branchCity
              branchState
              counter
              firstName
              lastName
              email
              phoneNumber
              mobilePhoneNumber
              status
              s3BucketPicLink
              createdAt
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
            }
            owner
            members
          }
          subOrder {
            items {
              id
              chain
              amount
              status
              type
              createdAt
              transaction {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              order {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            nextToken
          }
          owner
          members
        }
        owner
        members
      }
      nextToken
    }
    owner
    members
  }
}
`;
export const onCreateSubOrder = `subscription OnCreateSubOrder($owner: String, $members: String) {
  onCreateSubOrder(owner: $owner, members: $members) {
    id
    chain
    amount
    status
    type
    createdAt
    transaction {
      id
      number
      amount
      currency
      valutaDate
      bookingDate
      sequenceNumber
      ktoText
      mandat
      mandatSignatureDate
      auftrag
      metaTyp
      auftragsArt
      ereignis
      ereignisStatus
      internalBookingText
      externalBookingText
      status
      createdAt
      updatedAt
      sfType
      sfARN
      stateMachineToken
      bankAccount {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      owner
      members
    }
    order {
      id
      productId
      businessPartnerId
      personId
      amount
      sfType
      sfARN
      sfStartDate
      status
      createdAt
      EndOfStateMachine
      stateMachineToken
      product {
        id
        number
        name
        category
        strategy
        recommendedStrategy
        recommendedName
        balanceActual
        balanceStart
        balanceDate
        totalChangeBegin
        totalChangeYearStart
        twrPerformanceStart
        twrPerformanceYearStart
        mwrPerformanceStart
        mwrPerformanceYearStart
        statusProduct
        portfolioHistory
        portfolioHistoryYear
        performanceStartDate
        retirementYear
        stateMachineToken
        wphgInitialAmount
        initialAmountReceived
        wphgExpireDate
        wphg
        balanceClosed
        balanceClosedDate
        createdAt
        depotBalance
        depotItems
        cashoutPlan {
          id
          rate
          counter
          interval
          startDate
          endDate
          nextDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        liquidAccounts {
          items {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        depots {
          items {
            id
            number
            balance
            twr
            mwr
            profit
            depotItems {
              items {
                id
                name
                number
                quantity
                value
                valueCurrency
                einstandskurs
                aktuellerKurs
                notRealGUV
                notRealGUVCurrency
                notRealGUVPercentage
                referenceDate
                avaloqAssetId
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        conditions {
          items {
            id
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            condition {
              id
              advisor
              category
              strategy
              accountType
              minInvest
              minIncrease
              minDecrease
              minRate
              allowance
              cashoutplan
              savingsplan
              consulting
              feeQuirion
              feeAdvisor
              validFrom
              validTo
              homepageUrl
              conditionLink
              advisorConfirmText
              contact
              phoneNumber
              description
              descriptionTitle
              title
              name
              newBp
              newIps
              costProfile
              products {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
            }
            owner
            members
          }
          nextToken
        }
        actions {
          items {
            id
            action {
              id
              trigger
              dateFrom
              dateTo
              advisor
              category
              accountType
              existingCustomer
              newCustomer
              tasks
              name
              actionTCLink
              accept
              products {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      businessPartner {
        id
        number
        accountType
        name
        statusContract
        avaloqSync
        advisor
        branch
        balanceActual
        balanceDate
        balanceStart
        totalChangeBegin
        totalChangeYearStart
        twrPerformanceStart
        twrPerformanceYearStart
        mwrPerformanceStart
        mwrPerformanceYearStart
        joinedMember
        childMember
        otherMembers
        portfolioHistory
        portfolioHistoryYear
        performanceStartDate
        stateMachineToken
        createdAt
        openedAt
        closedAt
        wphgExpireDate
        wphg
        businessPartnerCode {
          items {
            id
            attorney
            businessPartnerId
            accountCode
            accountCodeStatus
            acceptedByOwner
            owner
            members
          }
          nextToken
        }
        persons {
          items {
            id
            role
            waitForCode
            waitForBirthCertificate
            bpId
            createdAt
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        products {
          items {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        consultant {
          id
          branch
          branchStreet
          branchZip
          branchCity
          branchState
          counter
          firstName
          lastName
          email
          phoneNumber
          mobilePhoneNumber
          status
          s3BucketPicLink
          createdAt
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
        }
        owner
        members
      }
      person {
        id
        eMail
        externalId
        avaloqId
        postIdent
        communicationEMail
        communicationEMailVerified
        dataComplete
        needToMigrateDoc
        identityId
        gender
        salutation
        title
        titleAdd
        firstName
        middleName
        lastName
        phoneNumber
        mobilePhoneNumber
        passportNumber
        passportIssuer
        passportIssuerDate
        passportValidToDate
        passportPlaceOfIssue
        passportCountryOfIssuer
        birthCertificateNumber
        birthCertificateIssuer
        birthCertificateIssuerDate
        birthCertificateCountryOfIssuer
        birthCertificateInitialEMail
        nationality
        street
        streetNumber
        zip
        city
        state
        country
        addressType
        maritalStatus
        taxNumber
        birthday
        birthplace
        nativeCountry
        occupation
        occupationIndustry
        taxOnlyInGermany
        additionalTax
        mmw
        campaign
        postIdentCaseId
        identificationMethod
        identificationStatus
        identificationType
        utmSource
        utmMedium
        utmCampaign
        utmData
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
        businessPartners {
          items {
            id
            role
            waitForCode
            waitForBirthCertificate
            bpId
            createdAt
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        postbox {
          items {
            id
            title
            date
            readTime
            status
            documentKey
            searchField
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        consultant {
          id
          branch
          branchStreet
          branchZip
          branchCity
          branchState
          counter
          firstName
          lastName
          email
          phoneNumber
          mobilePhoneNumber
          status
          s3BucketPicLink
          createdAt
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
        }
        owner
        members
      }
      subOrder {
        items {
          id
          chain
          amount
          status
          type
          createdAt
          transaction {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          order {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onUpdateSubOrder = `subscription OnUpdateSubOrder($owner: String, $members: String) {
  onUpdateSubOrder(owner: $owner, members: $members) {
    id
    chain
    amount
    status
    type
    createdAt
    transaction {
      id
      number
      amount
      currency
      valutaDate
      bookingDate
      sequenceNumber
      ktoText
      mandat
      mandatSignatureDate
      auftrag
      metaTyp
      auftragsArt
      ereignis
      ereignisStatus
      internalBookingText
      externalBookingText
      status
      createdAt
      updatedAt
      sfType
      sfARN
      stateMachineToken
      bankAccount {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      owner
      members
    }
    order {
      id
      productId
      businessPartnerId
      personId
      amount
      sfType
      sfARN
      sfStartDate
      status
      createdAt
      EndOfStateMachine
      stateMachineToken
      product {
        id
        number
        name
        category
        strategy
        recommendedStrategy
        recommendedName
        balanceActual
        balanceStart
        balanceDate
        totalChangeBegin
        totalChangeYearStart
        twrPerformanceStart
        twrPerformanceYearStart
        mwrPerformanceStart
        mwrPerformanceYearStart
        statusProduct
        portfolioHistory
        portfolioHistoryYear
        performanceStartDate
        retirementYear
        stateMachineToken
        wphgInitialAmount
        initialAmountReceived
        wphgExpireDate
        wphg
        balanceClosed
        balanceClosedDate
        createdAt
        depotBalance
        depotItems
        cashoutPlan {
          id
          rate
          counter
          interval
          startDate
          endDate
          nextDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        liquidAccounts {
          items {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        depots {
          items {
            id
            number
            balance
            twr
            mwr
            profit
            depotItems {
              items {
                id
                name
                number
                quantity
                value
                valueCurrency
                einstandskurs
                aktuellerKurs
                notRealGUV
                notRealGUVCurrency
                notRealGUVPercentage
                referenceDate
                avaloqAssetId
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        conditions {
          items {
            id
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            condition {
              id
              advisor
              category
              strategy
              accountType
              minInvest
              minIncrease
              minDecrease
              minRate
              allowance
              cashoutplan
              savingsplan
              consulting
              feeQuirion
              feeAdvisor
              validFrom
              validTo
              homepageUrl
              conditionLink
              advisorConfirmText
              contact
              phoneNumber
              description
              descriptionTitle
              title
              name
              newBp
              newIps
              costProfile
              products {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
            }
            owner
            members
          }
          nextToken
        }
        actions {
          items {
            id
            action {
              id
              trigger
              dateFrom
              dateTo
              advisor
              category
              accountType
              existingCustomer
              newCustomer
              tasks
              name
              actionTCLink
              accept
              products {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      businessPartner {
        id
        number
        accountType
        name
        statusContract
        avaloqSync
        advisor
        branch
        balanceActual
        balanceDate
        balanceStart
        totalChangeBegin
        totalChangeYearStart
        twrPerformanceStart
        twrPerformanceYearStart
        mwrPerformanceStart
        mwrPerformanceYearStart
        joinedMember
        childMember
        otherMembers
        portfolioHistory
        portfolioHistoryYear
        performanceStartDate
        stateMachineToken
        createdAt
        openedAt
        closedAt
        wphgExpireDate
        wphg
        businessPartnerCode {
          items {
            id
            attorney
            businessPartnerId
            accountCode
            accountCodeStatus
            acceptedByOwner
            owner
            members
          }
          nextToken
        }
        persons {
          items {
            id
            role
            waitForCode
            waitForBirthCertificate
            bpId
            createdAt
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        products {
          items {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        consultant {
          id
          branch
          branchStreet
          branchZip
          branchCity
          branchState
          counter
          firstName
          lastName
          email
          phoneNumber
          mobilePhoneNumber
          status
          s3BucketPicLink
          createdAt
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
        }
        owner
        members
      }
      person {
        id
        eMail
        externalId
        avaloqId
        postIdent
        communicationEMail
        communicationEMailVerified
        dataComplete
        needToMigrateDoc
        identityId
        gender
        salutation
        title
        titleAdd
        firstName
        middleName
        lastName
        phoneNumber
        mobilePhoneNumber
        passportNumber
        passportIssuer
        passportIssuerDate
        passportValidToDate
        passportPlaceOfIssue
        passportCountryOfIssuer
        birthCertificateNumber
        birthCertificateIssuer
        birthCertificateIssuerDate
        birthCertificateCountryOfIssuer
        birthCertificateInitialEMail
        nationality
        street
        streetNumber
        zip
        city
        state
        country
        addressType
        maritalStatus
        taxNumber
        birthday
        birthplace
        nativeCountry
        occupation
        occupationIndustry
        taxOnlyInGermany
        additionalTax
        mmw
        campaign
        postIdentCaseId
        identificationMethod
        identificationStatus
        identificationType
        utmSource
        utmMedium
        utmCampaign
        utmData
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
        businessPartners {
          items {
            id
            role
            waitForCode
            waitForBirthCertificate
            bpId
            createdAt
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        postbox {
          items {
            id
            title
            date
            readTime
            status
            documentKey
            searchField
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        consultant {
          id
          branch
          branchStreet
          branchZip
          branchCity
          branchState
          counter
          firstName
          lastName
          email
          phoneNumber
          mobilePhoneNumber
          status
          s3BucketPicLink
          createdAt
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
        }
        owner
        members
      }
      subOrder {
        items {
          id
          chain
          amount
          status
          type
          createdAt
          transaction {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          order {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
export const onDeleteSubOrder = `subscription OnDeleteSubOrder($owner: String, $members: String) {
  onDeleteSubOrder(owner: $owner, members: $members) {
    id
    chain
    amount
    status
    type
    createdAt
    transaction {
      id
      number
      amount
      currency
      valutaDate
      bookingDate
      sequenceNumber
      ktoText
      mandat
      mandatSignatureDate
      auftrag
      metaTyp
      auftragsArt
      ereignis
      ereignisStatus
      internalBookingText
      externalBookingText
      status
      createdAt
      updatedAt
      sfType
      sfARN
      stateMachineToken
      bankAccount {
        id
        number
        name
        accountHolder
        iban
        bic
        currency
        exchangeRate
        accountType
        balance
        balanceEur
        reference
        eod
        eodDate
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        transactions {
          items {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      owner
      members
    }
    order {
      id
      productId
      businessPartnerId
      personId
      amount
      sfType
      sfARN
      sfStartDate
      status
      createdAt
      EndOfStateMachine
      stateMachineToken
      product {
        id
        number
        name
        category
        strategy
        recommendedStrategy
        recommendedName
        balanceActual
        balanceStart
        balanceDate
        totalChangeBegin
        totalChangeYearStart
        twrPerformanceStart
        twrPerformanceYearStart
        mwrPerformanceStart
        mwrPerformanceYearStart
        statusProduct
        portfolioHistory
        portfolioHistoryYear
        performanceStartDate
        retirementYear
        stateMachineToken
        wphgInitialAmount
        initialAmountReceived
        wphgExpireDate
        wphg
        balanceClosed
        balanceClosedDate
        createdAt
        depotBalance
        depotItems
        cashoutPlan {
          id
          rate
          counter
          interval
          startDate
          endDate
          nextDate
          bankAccount {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        liquidAccounts {
          items {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        savingsPlans {
          items {
            id
            name
            rate
            startDate
            endDate
            nextDate
            executionDay
            counter
            interval
            mandat
            mandatSignatureDate
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        depots {
          items {
            id
            number
            balance
            twr
            mwr
            profit
            depotItems {
              items {
                id
                name
                number
                quantity
                value
                valueCurrency
                einstandskurs
                aktuellerKurs
                notRealGUV
                notRealGUVCurrency
                notRealGUVPercentage
                referenceDate
                avaloqAssetId
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        conditions {
          items {
            id
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            condition {
              id
              advisor
              category
              strategy
              accountType
              minInvest
              minIncrease
              minDecrease
              minRate
              allowance
              cashoutplan
              savingsplan
              consulting
              feeQuirion
              feeAdvisor
              validFrom
              validTo
              homepageUrl
              conditionLink
              advisorConfirmText
              contact
              phoneNumber
              description
              descriptionTitle
              title
              name
              newBp
              newIps
              costProfile
              products {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
            }
            owner
            members
          }
          nextToken
        }
        actions {
          items {
            id
            action {
              id
              trigger
              dateFrom
              dateTo
              advisor
              category
              accountType
              existingCustomer
              newCustomer
              tasks
              name
              actionTCLink
              accept
              products {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        owner
        members
      }
      businessPartner {
        id
        number
        accountType
        name
        statusContract
        avaloqSync
        advisor
        branch
        balanceActual
        balanceDate
        balanceStart
        totalChangeBegin
        totalChangeYearStart
        twrPerformanceStart
        twrPerformanceYearStart
        mwrPerformanceStart
        mwrPerformanceYearStart
        joinedMember
        childMember
        otherMembers
        portfolioHistory
        portfolioHistoryYear
        performanceStartDate
        stateMachineToken
        createdAt
        openedAt
        closedAt
        wphgExpireDate
        wphg
        businessPartnerCode {
          items {
            id
            attorney
            businessPartnerId
            accountCode
            accountCodeStatus
            acceptedByOwner
            owner
            members
          }
          nextToken
        }
        persons {
          items {
            id
            role
            waitForCode
            waitForBirthCertificate
            bpId
            createdAt
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        products {
          items {
            id
            number
            name
            category
            strategy
            recommendedStrategy
            recommendedName
            balanceActual
            balanceStart
            balanceDate
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            statusProduct
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            retirementYear
            stateMachineToken
            wphgInitialAmount
            initialAmountReceived
            wphgExpireDate
            wphg
            balanceClosed
            balanceClosedDate
            createdAt
            depotBalance
            depotItems
            cashoutPlan {
              id
              rate
              counter
              interval
              startDate
              endDate
              nextDate
              bankAccount {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              owner
              members
            }
            liquidAccounts {
              items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                eod
                eodDate
                savingsPlans {
                  items {
                    id
                    name
                    rate
                    startDate
                    endDate
                    nextDate
                    executionDay
                    counter
                    interval
                    mandat
                    mandatSignatureDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    createdAt
                    updatedAt
                    sfType
                    sfARN
                    stateMachineToken
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            depots {
              items {
                id
                number
                balance
                twr
                mwr
                profit
                depotItems {
                  items {
                    id
                    name
                    number
                    quantity
                    value
                    valueCurrency
                    einstandskurs
                    aktuellerKurs
                    notRealGUV
                    notRealGUVCurrency
                    notRealGUVPercentage
                    referenceDate
                    avaloqAssetId
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            orders {
              items {
                id
                productId
                businessPartnerId
                personId
                amount
                sfType
                sfARN
                sfStartDate
                status
                createdAt
                EndOfStateMachine
                stateMachineToken
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                businessPartner {
                  id
                  number
                  accountType
                  name
                  statusContract
                  avaloqSync
                  advisor
                  branch
                  balanceActual
                  balanceDate
                  balanceStart
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  joinedMember
                  childMember
                  otherMembers
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  stateMachineToken
                  createdAt
                  openedAt
                  closedAt
                  wphgExpireDate
                  wphg
                  businessPartnerCode {
                    items {
                      id
                      attorney
                      businessPartnerId
                      accountCode
                      accountCodeStatus
                      acceptedByOwner
                      owner
                      members
                    }
                    nextToken
                  }
                  persons {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  products {
                    items {
                      id
                      number
                      name
                      category
                      strategy
                      recommendedStrategy
                      recommendedName
                      balanceActual
                      balanceStart
                      balanceDate
                      totalChangeBegin
                      totalChangeYearStart
                      twrPerformanceStart
                      twrPerformanceYearStart
                      mwrPerformanceStart
                      mwrPerformanceYearStart
                      statusProduct
                      portfolioHistory
                      portfolioHistoryYear
                      performanceStartDate
                      retirementYear
                      stateMachineToken
                      wphgInitialAmount
                      initialAmountReceived
                      wphgExpireDate
                      wphg
                      balanceClosed
                      balanceClosedDate
                      createdAt
                      depotBalance
                      depotItems
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  bankAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                person {
                  id
                  eMail
                  externalId
                  avaloqId
                  postIdent
                  communicationEMail
                  communicationEMailVerified
                  dataComplete
                  needToMigrateDoc
                  identityId
                  gender
                  salutation
                  title
                  titleAdd
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  mobilePhoneNumber
                  passportNumber
                  passportIssuer
                  passportIssuerDate
                  passportValidToDate
                  passportPlaceOfIssue
                  passportCountryOfIssuer
                  birthCertificateNumber
                  birthCertificateIssuer
                  birthCertificateIssuerDate
                  birthCertificateCountryOfIssuer
                  birthCertificateInitialEMail
                  nationality
                  street
                  streetNumber
                  zip
                  city
                  state
                  country
                  addressType
                  maritalStatus
                  taxNumber
                  birthday
                  birthplace
                  nativeCountry
                  occupation
                  occupationIndustry
                  taxOnlyInGermany
                  additionalTax
                  mmw
                  campaign
                  postIdentCaseId
                  identificationMethod
                  identificationStatus
                  identificationType
                  utmSource
                  utmMedium
                  utmCampaign
                  utmData
                  ndl {
                    id
                    name
                    city
                    zip
                    street
                    streetNr
                  }
                  businessPartners {
                    items {
                      id
                      role
                      waitForCode
                      waitForBirthCertificate
                      bpId
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  postbox {
                    items {
                      id
                      title
                      date
                      readTime
                      status
                      documentKey
                      searchField
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  consultant {
                    id
                    branch
                    branchStreet
                    branchZip
                    branchCity
                    branchState
                    counter
                    firstName
                    lastName
                    email
                    phoneNumber
                    mobilePhoneNumber
                    status
                    s3BucketPicLink
                    createdAt
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                  }
                  owner
                  members
                }
                subOrder {
                  items {
                    id
                    chain
                    amount
                    status
                    type
                    createdAt
                    transaction {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    order {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    owner
                    members
                  }
                  nextToken
                }
                owner
                members
              }
              nextToken
            }
            conditions {
              items {
                id
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                condition {
                  id
                  advisor
                  category
                  strategy
                  accountType
                  minInvest
                  minIncrease
                  minDecrease
                  minRate
                  allowance
                  cashoutplan
                  savingsplan
                  consulting
                  feeQuirion
                  feeAdvisor
                  validFrom
                  validTo
                  homepageUrl
                  conditionLink
                  advisorConfirmText
                  contact
                  phoneNumber
                  description
                  descriptionTitle
                  title
                  name
                  newBp
                  newIps
                  costProfile
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                }
                owner
                members
              }
              nextToken
            }
            actions {
              items {
                id
                action {
                  id
                  trigger
                  dateFrom
                  dateTo
                  advisor
                  category
                  accountType
                  existingCustomer
                  newCustomer
                  tasks
                  name
                  actionTCLink
                  accept
                  products {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                product {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            number
            name
            accountHolder
            iban
            bic
            currency
            exchangeRate
            accountType
            balance
            balanceEur
            reference
            eod
            eodDate
            savingsPlans {
              items {
                id
                name
                rate
                startDate
                endDate
                nextDate
                executionDay
                counter
                interval
                mandat
                mandatSignatureDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            transactions {
              items {
                id
                number
                amount
                currency
                valutaDate
                bookingDate
                sequenceNumber
                ktoText
                mandat
                mandatSignatureDate
                auftrag
                metaTyp
                auftragsArt
                ereignis
                ereignisStatus
                internalBookingText
                externalBookingText
                status
                createdAt
                updatedAt
                sfType
                sfARN
                stateMachineToken
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        consultant {
          id
          branch
          branchStreet
          branchZip
          branchCity
          branchState
          counter
          firstName
          lastName
          email
          phoneNumber
          mobilePhoneNumber
          status
          s3BucketPicLink
          createdAt
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
        }
        owner
        members
      }
      person {
        id
        eMail
        externalId
        avaloqId
        postIdent
        communicationEMail
        communicationEMailVerified
        dataComplete
        needToMigrateDoc
        identityId
        gender
        salutation
        title
        titleAdd
        firstName
        middleName
        lastName
        phoneNumber
        mobilePhoneNumber
        passportNumber
        passportIssuer
        passportIssuerDate
        passportValidToDate
        passportPlaceOfIssue
        passportCountryOfIssuer
        birthCertificateNumber
        birthCertificateIssuer
        birthCertificateIssuerDate
        birthCertificateCountryOfIssuer
        birthCertificateInitialEMail
        nationality
        street
        streetNumber
        zip
        city
        state
        country
        addressType
        maritalStatus
        taxNumber
        birthday
        birthplace
        nativeCountry
        occupation
        occupationIndustry
        taxOnlyInGermany
        additionalTax
        mmw
        campaign
        postIdentCaseId
        identificationMethod
        identificationStatus
        identificationType
        utmSource
        utmMedium
        utmCampaign
        utmData
        ndl {
          id
          name
          city
          zip
          street
          streetNr
        }
        businessPartners {
          items {
            id
            role
            waitForCode
            waitForBirthCertificate
            bpId
            createdAt
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        postbox {
          items {
            id
            title
            date
            readTime
            status
            documentKey
            searchField
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            owner
            members
          }
          nextToken
        }
        orders {
          items {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          nextToken
        }
        consultant {
          id
          branch
          branchStreet
          branchZip
          branchCity
          branchState
          counter
          firstName
          lastName
          email
          phoneNumber
          mobilePhoneNumber
          status
          s3BucketPicLink
          createdAt
          ndl {
            id
            name
            city
            zip
            street
            streetNr
          }
        }
        owner
        members
      }
      subOrder {
        items {
          id
          chain
          amount
          status
          type
          createdAt
          transaction {
            id
            number
            amount
            currency
            valutaDate
            bookingDate
            sequenceNumber
            ktoText
            mandat
            mandatSignatureDate
            auftrag
            metaTyp
            auftragsArt
            ereignis
            ereignisStatus
            internalBookingText
            externalBookingText
            status
            createdAt
            updatedAt
            sfType
            sfARN
            stateMachineToken
            bankAccount {
              id
              number
              name
              accountHolder
              iban
              bic
              currency
              exchangeRate
              accountType
              balance
              balanceEur
              reference
              eod
              eodDate
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              transactions {
                items {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            owner
            members
          }
          order {
            id
            productId
            businessPartnerId
            personId
            amount
            sfType
            sfARN
            sfStartDate
            status
            createdAt
            EndOfStateMachine
            stateMachineToken
            product {
              id
              number
              name
              category
              strategy
              recommendedStrategy
              recommendedName
              balanceActual
              balanceStart
              balanceDate
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              statusProduct
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              retirementYear
              stateMachineToken
              wphgInitialAmount
              initialAmountReceived
              wphgExpireDate
              wphg
              balanceClosed
              balanceClosedDate
              createdAt
              depotBalance
              depotItems
              cashoutPlan {
                id
                rate
                counter
                interval
                startDate
                endDate
                nextDate
                bankAccount {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              liquidAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              savingsPlans {
                items {
                  id
                  name
                  rate
                  startDate
                  endDate
                  nextDate
                  executionDay
                  counter
                  interval
                  mandat
                  mandatSignatureDate
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              depots {
                items {
                  id
                  number
                  balance
                  twr
                  mwr
                  profit
                  depotItems {
                    items {
                      id
                      name
                      number
                      quantity
                      value
                      valueCurrency
                      einstandskurs
                      aktuellerKurs
                      notRealGUV
                      notRealGUVCurrency
                      notRealGUVPercentage
                      referenceDate
                      avaloqAssetId
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              conditions {
                items {
                  id
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  condition {
                    id
                    advisor
                    category
                    strategy
                    accountType
                    minInvest
                    minIncrease
                    minDecrease
                    minRate
                    allowance
                    cashoutplan
                    savingsplan
                    consulting
                    feeQuirion
                    feeAdvisor
                    validFrom
                    validTo
                    homepageUrl
                    conditionLink
                    advisorConfirmText
                    contact
                    phoneNumber
                    description
                    descriptionTitle
                    title
                    name
                    newBp
                    newIps
                    costProfile
                    products {
                      nextToken
                    }
                  }
                  owner
                  members
                }
                nextToken
              }
              actions {
                items {
                  id
                  action {
                    id
                    trigger
                    dateFrom
                    dateTo
                    advisor
                    category
                    accountType
                    existingCustomer
                    newCustomer
                    tasks
                    name
                    actionTCLink
                    accept
                    products {
                      nextToken
                    }
                    owner
                    members
                  }
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              owner
              members
            }
            businessPartner {
              id
              number
              accountType
              name
              statusContract
              avaloqSync
              advisor
              branch
              balanceActual
              balanceDate
              balanceStart
              totalChangeBegin
              totalChangeYearStart
              twrPerformanceStart
              twrPerformanceYearStart
              mwrPerformanceStart
              mwrPerformanceYearStart
              joinedMember
              childMember
              otherMembers
              portfolioHistory
              portfolioHistoryYear
              performanceStartDate
              stateMachineToken
              createdAt
              openedAt
              closedAt
              wphgExpireDate
              wphg
              businessPartnerCode {
                items {
                  id
                  attorney
                  businessPartnerId
                  accountCode
                  accountCodeStatus
                  acceptedByOwner
                  owner
                  members
                }
                nextToken
              }
              persons {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              products {
                items {
                  id
                  number
                  name
                  category
                  strategy
                  recommendedStrategy
                  recommendedName
                  balanceActual
                  balanceStart
                  balanceDate
                  totalChangeBegin
                  totalChangeYearStart
                  twrPerformanceStart
                  twrPerformanceYearStart
                  mwrPerformanceStart
                  mwrPerformanceYearStart
                  statusProduct
                  portfolioHistory
                  portfolioHistoryYear
                  performanceStartDate
                  retirementYear
                  stateMachineToken
                  wphgInitialAmount
                  initialAmountReceived
                  wphgExpireDate
                  wphg
                  balanceClosed
                  balanceClosedDate
                  createdAt
                  depotBalance
                  depotItems
                  cashoutPlan {
                    id
                    rate
                    counter
                    interval
                    startDate
                    endDate
                    nextDate
                    bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    owner
                    members
                  }
                  liquidAccounts {
                    items {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                    }
                    nextToken
                  }
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  depots {
                    items {
                      id
                      number
                      balance
                      twr
                      mwr
                      profit
                      owner
                      members
                    }
                    nextToken
                  }
                  orders {
                    items {
                      id
                      productId
                      businessPartnerId
                      personId
                      amount
                      sfType
                      sfARN
                      sfStartDate
                      status
                      createdAt
                      EndOfStateMachine
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  conditions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  actions {
                    items {
                      id
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              bankAccounts {
                items {
                  id
                  number
                  name
                  accountHolder
                  iban
                  bic
                  currency
                  exchangeRate
                  accountType
                  balance
                  balanceEur
                  reference
                  eod
                  eodDate
                  savingsPlans {
                    items {
                      id
                      name
                      rate
                      startDate
                      endDate
                      nextDate
                      executionDay
                      counter
                      interval
                      mandat
                      mandatSignatureDate
                      owner
                      members
                    }
                    nextToken
                  }
                  transactions {
                    items {
                      id
                      number
                      amount
                      currency
                      valutaDate
                      bookingDate
                      sequenceNumber
                      ktoText
                      mandat
                      mandatSignatureDate
                      auftrag
                      metaTyp
                      auftragsArt
                      ereignis
                      ereignisStatus
                      internalBookingText
                      externalBookingText
                      status
                      createdAt
                      updatedAt
                      sfType
                      sfARN
                      stateMachineToken
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            person {
              id
              eMail
              externalId
              avaloqId
              postIdent
              communicationEMail
              communicationEMailVerified
              dataComplete
              needToMigrateDoc
              identityId
              gender
              salutation
              title
              titleAdd
              firstName
              middleName
              lastName
              phoneNumber
              mobilePhoneNumber
              passportNumber
              passportIssuer
              passportIssuerDate
              passportValidToDate
              passportPlaceOfIssue
              passportCountryOfIssuer
              birthCertificateNumber
              birthCertificateIssuer
              birthCertificateIssuerDate
              birthCertificateCountryOfIssuer
              birthCertificateInitialEMail
              nationality
              street
              streetNumber
              zip
              city
              state
              country
              addressType
              maritalStatus
              taxNumber
              birthday
              birthplace
              nativeCountry
              occupation
              occupationIndustry
              taxOnlyInGermany
              additionalTax
              mmw
              campaign
              postIdentCaseId
              identificationMethod
              identificationStatus
              identificationType
              utmSource
              utmMedium
              utmCampaign
              utmData
              ndl {
                id
                name
                city
                zip
                street
                streetNr
              }
              businessPartners {
                items {
                  id
                  role
                  waitForCode
                  waitForBirthCertificate
                  bpId
                  createdAt
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              postbox {
                items {
                  id
                  title
                  date
                  readTime
                  status
                  documentKey
                  searchField
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                nextToken
              }
              orders {
                items {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                nextToken
              }
              consultant {
                id
                branch
                branchStreet
                branchZip
                branchCity
                branchState
                counter
                firstName
                lastName
                email
                phoneNumber
                mobilePhoneNumber
                status
                s3BucketPicLink
                createdAt
                ndl {
                  id
                  name
                  city
                  zip
                  street
                  streetNr
                }
              }
              owner
              members
            }
            subOrder {
              items {
                id
                chain
                amount
                status
                type
                createdAt
                transaction {
                  id
                  number
                  amount
                  currency
                  valutaDate
                  bookingDate
                  sequenceNumber
                  ktoText
                  mandat
                  mandatSignatureDate
                  auftrag
                  metaTyp
                  auftragsArt
                  ereignis
                  ereignisStatus
                  internalBookingText
                  externalBookingText
                  status
                  createdAt
                  updatedAt
                  sfType
                  sfARN
                  stateMachineToken
                  bankAccount {
                    id
                    number
                    name
                    accountHolder
                    iban
                    bic
                    currency
                    exchangeRate
                    accountType
                    balance
                    balanceEur
                    reference
                    eod
                    eodDate
                    savingsPlans {
                      nextToken
                    }
                    transactions {
                      nextToken
                    }
                    owner
                    members
                  }
                  owner
                  members
                }
                order {
                  id
                  productId
                  businessPartnerId
                  personId
                  amount
                  sfType
                  sfARN
                  sfStartDate
                  status
                  createdAt
                  EndOfStateMachine
                  stateMachineToken
                  product {
                    id
                    number
                    name
                    category
                    strategy
                    recommendedStrategy
                    recommendedName
                    balanceActual
                    balanceStart
                    balanceDate
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    statusProduct
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    retirementYear
                    stateMachineToken
                    wphgInitialAmount
                    initialAmountReceived
                    wphgExpireDate
                    wphg
                    balanceClosed
                    balanceClosedDate
                    createdAt
                    depotBalance
                    depotItems
                    cashoutPlan {
                      id
                      rate
                      counter
                      interval
                      startDate
                      endDate
                      nextDate
                      owner
                      members
                    }
                    liquidAccounts {
                      nextToken
                    }
                    savingsPlans {
                      nextToken
                    }
                    depots {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    conditions {
                      nextToken
                    }
                    actions {
                      nextToken
                    }
                    owner
                    members
                  }
                  businessPartner {
                    id
                    number
                    accountType
                    name
                    statusContract
                    avaloqSync
                    advisor
                    branch
                    balanceActual
                    balanceDate
                    balanceStart
                    totalChangeBegin
                    totalChangeYearStart
                    twrPerformanceStart
                    twrPerformanceYearStart
                    mwrPerformanceStart
                    mwrPerformanceYearStart
                    joinedMember
                    childMember
                    otherMembers
                    portfolioHistory
                    portfolioHistoryYear
                    performanceStartDate
                    stateMachineToken
                    createdAt
                    openedAt
                    closedAt
                    wphgExpireDate
                    wphg
                    businessPartnerCode {
                      nextToken
                    }
                    persons {
                      nextToken
                    }
                    products {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    bankAccounts {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  person {
                    id
                    eMail
                    externalId
                    avaloqId
                    postIdent
                    communicationEMail
                    communicationEMailVerified
                    dataComplete
                    needToMigrateDoc
                    identityId
                    gender
                    salutation
                    title
                    titleAdd
                    firstName
                    middleName
                    lastName
                    phoneNumber
                    mobilePhoneNumber
                    passportNumber
                    passportIssuer
                    passportIssuerDate
                    passportValidToDate
                    passportPlaceOfIssue
                    passportCountryOfIssuer
                    birthCertificateNumber
                    birthCertificateIssuer
                    birthCertificateIssuerDate
                    birthCertificateCountryOfIssuer
                    birthCertificateInitialEMail
                    nationality
                    street
                    streetNumber
                    zip
                    city
                    state
                    country
                    addressType
                    maritalStatus
                    taxNumber
                    birthday
                    birthplace
                    nativeCountry
                    occupation
                    occupationIndustry
                    taxOnlyInGermany
                    additionalTax
                    mmw
                    campaign
                    postIdentCaseId
                    identificationMethod
                    identificationStatus
                    identificationType
                    utmSource
                    utmMedium
                    utmCampaign
                    utmData
                    ndl {
                      id
                      name
                      city
                      zip
                      street
                      streetNr
                    }
                    businessPartners {
                      nextToken
                    }
                    postbox {
                      nextToken
                    }
                    orders {
                      nextToken
                    }
                    consultant {
                      id
                      branch
                      branchStreet
                      branchZip
                      branchCity
                      branchState
                      counter
                      firstName
                      lastName
                      email
                      phoneNumber
                      mobilePhoneNumber
                      status
                      s3BucketPicLink
                      createdAt
                    }
                    owner
                    members
                  }
                  subOrder {
                    items {
                      id
                      chain
                      amount
                      status
                      type
                      createdAt
                      owner
                      members
                    }
                    nextToken
                  }
                  owner
                  members
                }
                owner
                members
              }
              nextToken
            }
            owner
            members
          }
          owner
          members
        }
        nextToken
      }
      owner
      members
    }
    owner
    members
  }
}
`;
